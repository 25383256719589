import axios from 'axios'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import {
  CheckoutFilter,
  CheckoutPageResultDto,
  Checkout,
  CheckoutCreateDto,
  CheckoutStatusPatchDto,
} from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/checkout`

export default {
  post: async (body: CheckoutCreateDto, token: string) => {
    const url = `${baseUrl}`
    const { data } = await axios.post<CheckoutCreateDto>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  get: async (
    params: CheckoutFilter,
    token: string,
  ): Promise<CheckoutPageResultDto> => {
    const url = `${baseUrl}`
    const urlWithParams = new URL(url)
    urlWithParams.search = objectToSearchParams(params).toString()

    const { data } = await axios.get<CheckoutPageResultDto>(
      urlWithParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return data
  },
  id: {
    get: async (id: string, token: string): Promise<Checkout> => {
      const url = `${baseUrl}/${id}`
      const { data } = await axios.get<Checkout>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    status: {
      patch: async (
        id: string,
        body: CheckoutStatusPatchDto,
        token: string,
      ) => {
        const url = `${baseUrl}/${id}/status`
        const { data } = await axios.patch<Checkout>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
    orderConfirmation: {
      put: async (id: string, orderConfirmation: File, token: string) => {
        const url = `${baseUrl}/${id}/orderConfirmation`
        const file = new FormData()
        file.append('file', orderConfirmation)
        const { data } = await axios.put<string>(url, file, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
      get: async (id: string, token: string) => {
        const url = `${baseUrl}/${id}/orderConfirmation`
        const { data } = await axios.get<string>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
  },
}
