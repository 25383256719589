import axios from 'axios'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import {
  CreateNewsletterSignUpDto,
  NewsletterSignUp,
  NewsletterSignUpResponseDto,
} from '../schemas'

export default {
  post: async (body: CreateNewsletterSignUpDto, token?: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/newsletter-sign-ups`
    const { data } = await axios.post<NewsletterSignUp>(url, body, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })
    return data
  },
  get: async (
    params: {
      skip?: number
      limit?: number
    } = {
      skip: 0,
      limit: 10,
    },
    token: string,
  ) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/newsletter-sign-ups`

    const urlWithParams = new URL(url)

    urlWithParams.search = objectToSearchParams(params).toString()
    const { data } = await axios.get<NewsletterSignUpResponseDto>(
      urlWithParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )
    return data
  },
}
