import { LegalInfo, LegalInfoUpdateDto } from '../schemas'

export default {
  termsOfService: {
    get: async () => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/legal-infos/terms-of-service`

      const response = await fetch(url)
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()
      return data
    },
    put: async (body: LegalInfoUpdateDto, token: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/legal-infos/terms-of-service`

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()

      return data
    },
  },
  imprint: {
    get: async () => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/legal-infos/imprint`

      const response = await fetch(url)
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()
      return data
    },
    put: async (body: LegalInfoUpdateDto, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/legal-infos/imprint`

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()

      return data
    },
  },
  aboutUs: {
    get: async () => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/legal-infos/about-us`

      const response = await fetch(url)
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()
      return data
    },
    put: async (body: LegalInfoUpdateDto, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/legal-infos/about-us`

      const response = await fetch(url, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: LegalInfo = await response.json()

      return data
    },
  },
}
