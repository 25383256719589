import axios from 'axios'
import { Comment, CommentDto, CommentResponseDto } from '../schemas'

const base = `${import.meta.env.VITE_RESALE_API_HOST}/comments`

export default {
  getAll: async (offerId: string, token: string) => {
    const res = await axios.get<CommentResponseDto>(base, {
      params: {
        skip: 0,
        limit: Number.MAX_SAFE_INTEGER,
        offerId: offerId,
      },
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data
  },
  create: async (commentDto: CommentDto, token: string) => {
    const res = await axios.post<Comment>(base, commentDto, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data
  },
  patch: async (commentId: string, commentDto: CommentDto, token: string) => {
    const res = await axios.patch<Comment>(`${base}/${commentId}`, commentDto, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data
  },
  delete: async (commentId: string, token: string) => {
    const res = await axios.delete<void>(`${base}/${commentId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return res.data
  },
}
