import { ref } from 'vue'

type Theme = 'light' | 'dark' | 'auto'

const selectedTheme = ref<Theme>(localStorage.theme || 'light')
const doc = document.firstElementChild
const setTheme = (theme: Theme) => {
  selectedTheme.value = theme
  localStorage.setItem('theme', theme)
  doc?.setAttribute('color-scheme', theme)
  const hasPreferColorSchemaDark = window.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches
  const hasThemeDarkSaved = localStorage.theme === 'dark'
  const hasThemeAutoSaved = localStorage.theme === 'auto'
  const hasNoThemeSaved = !('theme' in localStorage)
  const hasThemeInLocalStorage = 'theme' in localStorage

  if (
    hasThemeDarkSaved ||
    (hasPreferColorSchemaDark && (hasNoThemeSaved || hasThemeAutoSaved))
  ) {
    document.documentElement.classList.add('dark')
  } else if (hasThemeInLocalStorage) {
    document.documentElement.classList.remove('dark')
  }
}

const theme = localStorage.getItem('theme') as Theme | null
setTheme(theme || 'light')

export function useTheme() {
  return {
    selectedTheme,
    setTheme,
  }
}
