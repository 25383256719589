import offers from '../offers'
import { PromotionCreateDto, PromotionResponseDto } from '../schemas'

export default {
  ofTheDay: async (token?: string): Promise<PromotionResponseDto[]> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/promotions/of-the-day`

    console.log('get promotions of the week from: ', url)
    const response = await fetch(url)
    if (!response.ok) throw new Error(response.statusText)

    const data: PromotionResponseDto[] = await response.json()

    for (const promotion of data) {
      let relatedOffer
      try {
        relatedOffer = await offers.id.get(promotion.offer, token)
      } catch (e) {
        continue
      }
      if (relatedOffer) {
        promotion.promotedOffer = relatedOffer
      }
    }

    return data
  },
  id: {
    delete: async (id: string, token?: string): Promise<void> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/promotions/${id}`

      console.log('delete promotion from: ', url)
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)
    },
  },
  get: async (token?: string): Promise<PromotionResponseDto[]> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/promotions`

    console.log('get promotions from: ', url)
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: PromotionResponseDto[] = await response.json()

    return data
  },
  post: async (
    promotion: PromotionCreateDto,
    token?: string,
  ): Promise<PromotionResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/promotions`

    console.log('post promotion to: ', url)
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(promotion),
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: PromotionResponseDto = await response.json()

    return data
  },
}
