import axios from 'axios'
import { Bookmark, BookmarkRequestDto, SingleBookmark } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/bookmarks`

export default {
  getAll: async (token: string) => {
    const res = await axios.get<SingleBookmark[]>(baseUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  get: async (id: string, token: string) => {
    const url = `${baseUrl}/${id}`
    const res = await axios.get<Bookmark>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  post: async (body: BookmarkRequestDto, token: string) => {
    const res = await axios.post<SingleBookmark>(baseUrl, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  patch: async (id: string, body: BookmarkRequestDto, token: string) => {
    const url = `${baseUrl}/${id}`
    const res = await axios.patch<Bookmark>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  delete: async (id: string, token: string) => {
    const url = `${baseUrl}/${id}`
    const res = await axios.delete<void>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  updateBookmark: async (
    offerId: string,
    bookmarkIds: string[],
    token: string,
  ) => {
    const url = `${baseUrl}/offer/${offerId}`
    const res = await axios.put<Bookmark[]>(url, bookmarkIds, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
  getBookmarksCounter: async (offerId: string, token: string) => {
    const url = `${baseUrl}/offer/${offerId}`
    const res = await axios.get<number>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })

    return res.data
  },
}
