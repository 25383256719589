import axios from 'axios'
import { Feedback, FeedbackCreateDto, FeedbacksResponseDto } from '../schemas'

export default {
  post: async (body: FeedbackCreateDto, token?: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/feedbacks`
    const headers: HeadersInit = {}
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    const { data } = await axios.post<Feedback>(url, body, {
      headers,
    })
    return data
  },
  get: async (token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/feedbacks`
    const { data } = await axios.get<FeedbacksResponseDto>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
}
