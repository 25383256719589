import axios from 'axios'
import { CreateUserInformationDto, UserInformation } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/user-informations`

export default {
  email: {
    get: async (email: string, token: string) => {
      const url = `${baseUrl}/${email}`

      const { data } = await axios.get<UserInformation>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    patch: async (
      body: CreateUserInformationDto,
      email: string,
      token?: string,
    ) => {
      const url = `${baseUrl}/${email}`
      const { data } = await axios.patch<UserInformation>(url, body, {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      })
      return data
    },
  },
}
