import { computed } from 'vue'
import { useLanguage } from './useLanguage'
import { useCompanyTheme } from './useCompanyTheme'

const { i18nInstance } = useLanguage()
const { currentLanguage } = useLanguage()
const { getSiteTitle } = useCompanyTheme()

const siteTitle = computed(() => {
  return getSiteTitle.value
    ? getContentInCurrentLanguage(getSiteTitle.value)
    : `${i18nInstance.global.t('sbb')} ${import.meta.env.VITE_SITE_TITLE}`
})

const getContentInCurrentLanguage = (content: {
  de: string
  fr: string
  it: string
  en: string
}) => {
  if (!content || !currentLanguage.value) return ''
  if (typeof content == 'string') return content
  const isContentInCurrentLanguageEmpty = !content?.[currentLanguage.value]
  if (!isContentInCurrentLanguageEmpty) return content[currentLanguage.value]
  const fallbackContent = Object.values(content).find((value) => !!value)
  return fallbackContent || ''
}

export function useSiteTitle() {
  return siteTitle
}
