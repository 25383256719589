/* ---------------------------------- Offer ----------------------------------- */
import { Language } from '../../hooks/useLanguage'

export type OfferCreateDto = {
  title: Translations
  description: Translations
  price?: number
  currency: CurrencyEnum
  images: File[]
  documents: File[]
  links: Link[]
  goodsGroupId: string
  publishDate?: Date
  approvedAt?: Date
  children: string[]
  siblings: string[]
  contact?: {
    firstName: string
    lastName: string
    email: string
    phone?: string
    organizationUnit?: string
  }
  unit?: Translations
  storageLocation?: string
  transportOptions?: TransportType[]
  minimumQuantity?: number
  isTransportCostsExclusive?: boolean
  quantity?: number
  quantityUnit?: Unit
  zipCode?: string
  city?: string
  condition?: Condition
  availableFrom?: Date
  availableTill?: Date
  manufacturer?: string
  constructionYear?: number
  materialIds?: string[]
  length?: number
  width?: number
  height?: number
  weight?: number
  lengthUnit?: Unit
  widthUnit?: Unit
  heightUnit?: Unit
  weightUnit?: Unit
  visibility?: boolean
  thumbnailIndex?: number
  contactPerson?: boolean
}

export class OfferResponseDto {
  static get modelName() {
    return 'Offer'
  }

  constructor(
    public id: string,
    public _id: string,
    public title: Translations,
    public description: Translations,
    public currency: CurrencyEnum,
    public creator: string,
    public createdAt: string,
    public updatedAt: string,
    public images: string[],
    public thumbnail: string,
    public goodsGroup: GoodsGroupResponseDto,
    public status: OfferStatus,
    public children: string[],
    public siblings: string[],
    public quantity?: number,
    public quantityUnit?: Unit,
    public zipCode?: string,
    public city?: string,
    public condition?: Condition,
    public availableFrom?: Date,
    public availableTill?: Date,
    public price?: number,
    public documents?: File[],
    public links?: Link[],
    public publishDate?: Date,
    public approvedAt?: Date,
    public company?: string,
    public bids?: BidResponseDto[],
    public startDate?: Date,
    public endDate?: Date,
    public contact?: {
      firstName: string
      lastName: string
      email: string
      phone?: string
      organizationUnit?: string
    },
    public storageLocation?: string,
    public unit?: Translations,
    public __t: 'Offer' = 'Offer',
    public transportOptions?: TransportType[],
    public referenceId?: string,
    public minimumQuantity?: number,
    public isTransportCostsExclusive?: boolean,
    public manufacturer?: string,
    public constructionYear?: number,
    public materialIds?: string[],
    public length?: number,
    public width?: number,
    public height?: number,
    public weight?: number,
    public lengthUnit?: Unit,
    public widthUnit?: Unit,
    public heightUnit?: Unit,
    public weightUnit?: Unit,
    public visibility?: boolean,
    public thumbnailIndex?: number,
    public contactPerson?: boolean,
  ) {}
}

export type OfferPaginationResponseDto = {
  offers: (OfferResponseDto | AuctionResponseDto)[]
  count: number
}

export type OfferUpdateDto = {
  title?: Translations
  description?: Translations
  price?: number
  currency?: CurrencyEnum
  images?: File[]
  goodsGroupId?: string
  documents?: File[]
  links?: Link[]
  publishDate?: Date
  approvedAt?: Date
  children?: string[]
  siblings?: string[]
  contact?: {
    firstName: string
    lastName: string
    email: string
    phone?: string
    organizationUnit?: string
  }
  unit?: Translations
  storageLocation?: string
  transportOptions?: TransportType[]
  minimumQuantity?: number
  isTransportCostsExclusive?: boolean
  quantity?: number
  quantityUnit?: Unit
  zipCode?: string
  city?: string
  condition?: Condition
  availableFrom?: Date
  availableTill?: Date
  manufacturer?: string
  constructionYear?: number
  materialIds?: string[]
  length?: number
  width?: number
  height?: number
  weight?: number
  lengthUnit?: Unit
  widthUnit?: Unit
  heightUnit?: Unit
  weightUnit?: Unit
  visibility?: boolean
  thumbnailIndex?: number
  contactPerson?: boolean
}

export enum CurrencyEnum {
  EURO = 'EUR',
  SWISS_FRANC = 'CHF',
  US_DOLLAR = 'USD',
}

export enum OfferStatus {
  Created = 'Created',
  Approved = 'Approved',
  Declined = 'Declined',
  Archived = 'Archived',
}

export type Link = {
  url: string
  label: string
}

export enum Condition {
  Default = '---',
  New = 'New',
  Used = 'Used',
  Defect = 'Defect',
}

export enum Unit {
  Piece = 'Piece',
  Meter = 'Meter',
  SquareMeter = 'SquareMeter',
  CubicMeter = 'CubicMeter',
  Kilogram = 'Kilogram',
  CentiMeter = 'CentiMeter',
  MetricTons = 'MetricTons',
}

export enum PriceOption {
  isPriceOnRequest = 'isPriceOnRequest',
  isFixPrice = 'isFixPrice',
  isFree = 'isFree',
  isAuction = 'isAuction',
}

export enum AvailabilityOption {
  isAvailableNow = 'isAvailableNow',
  isAvailableLater = 'isAvailableLater',
}

/* ---------------------------------- Auction ----------------------------------- */
export type MinimumBidIncrement = 1 | 10 | 100 | 1000 | 10000
export const DEFAULT_MIN_BID_INCREMENT = 10 as MinimumBidIncrement

export type AuctionCreateDto = {
  title: Translations
  description: Translations
  startBidPrice: number
  minimumBidIncrement: MinimumBidIncrement
  price?: number
  currency: CurrencyEnum
  images: File[]
  documents: File[]
  links?: Link[]
  goodsGroupId: string
  startDate: Date
  endDate: Date
  publishDate?: Date
  approvedAt?: Date
  contact?: {
    firstName: string
    lastName: string
    email: string
    phone?: string
    organizationUnit?: string
  }
  unit?: Translations
  storageLocation?: string
  transportOptions?: TransportType[]
  minimumQuantity?: number
  isTransportCostsExclusive?: boolean
  quantity?: number
  quantityUnit?: Unit
  zipCode?: string
  city?: string
  condition?: Condition
  availableFrom?: Date
  availableTill?: Date
  manufacturer?: string
  constructionYear?: number
  materialIds?: string[]
  length?: number
  width?: number
  height?: number
  weight?: number
  lengthUnit?: Unit
  widthUnit?: Unit
  heightUnit?: Unit
  weightUnit?: Unit
  visibility?: boolean
  thumbnailIndex?: number
  contactPerson?: boolean
}

export type AuctionUpdateDto = {
  title?: Translations
  description?: Translations
  startBidPrice?: number
  price?: number
  minimumBidIncrement: MinimumBidIncrement
  currency?: CurrencyEnum
  images?: File[]
  documents?: File[]
  links?: Link[]
  goodsGroupId?: string
  startDate?: Date
  endDate?: Date
  publishDate?: Date
  approvedAt?: Date
  contact?: {
    firstName: string
    lastName: string
    email: string
    phone?: string
    organizationUnit?: string
  }
  unit?: Translations
  storageLocation?: string
  transportOptions?: TransportType[]
  minimumQuantity?: number
  isTransportCostsExclusive?: boolean
  quantity?: number
  quantityUnit?: Unit
  zipCode?: string
  city?: string
  condition?: Condition
  availableFrom?: Date
  availableTill?: Date
  manufacturer?: string
  constructionYear?: number
  materialIds?: string[]
  length?: number
  width?: number
  height?: number
  weight?: number
  lengthUnit?: Unit
  widthUnit?: Unit
  heightUnit?: Unit
  weightUnit?: Unit
  visibility?: boolean
  thumbnailIndex?: number
  contactPerson?: boolean
}

export class AuctionResponseDto {
  static get modelName() {
    return 'Auction'
  }

  constructor(
    public id: string,
    public title: Translations,
    public description: Translations,
    public startBidPrice: number,
    public currency: CurrencyEnum,
    public creator: string,
    public createdAt: string,
    public updatedAt: string,
    public images: string[],
    public thumbnail: string,
    public goodsGroup: GoodsGroupResponseDto,
    public status: OfferStatus,
    public startDate: Date,
    public bids: BidResponseDto[],
    public endDate: Date,
    public children: string[],
    public siblings: string[],
    public quantity?: number,
    public quantityUnit?: Unit,
    public zipCode?: string,
    public city?: string,
    public condition?: Condition,
    public availableFrom?: Date,
    public availableTill?: Date,
    public publishDate?: Date,
    public approvedAt?: Date,
    public company?: string,
    public price?: number,
    public minimumBidIncrement?: MinimumBidIncrement,
    public documents?: File[],
    public links?: Link[],
    public contact?: {
      firstName: string
      lastName: string
      email: string
      phone?: string
      organizationUnit?: string
    },
    public unit?: Translations,
    public storageLocation?: string,
    public __t: 'Auction' = 'Auction',
    public transportOptions?: TransportType[],
    public referenceId?: string,
    public minimumQuantity?: number,
    public isTransportCostsExclusive?: boolean,
    public manufacturer?: string,
    public constructionYear?: number,
    public materialIds?: string[],
    public length?: number,
    public width?: number,
    public height?: number,
    public weight?: number,
    public lengthUnit?: Unit,
    public widthUnit?: Unit,
    public heightUnit?: Unit,
    public weightUnit?: Unit,
    public visibility?: boolean,
    public thumbnailIndex?: number,
    public contactPerson?: boolean,
  ) {}
}

export type BidResponseDto = {
  id: string
  userId: string
  auction: string
  surcharge: number
}

/* -------------------------------- Promotions --------------------------------- */

export type PromotionResponseDto = {
  id: string
  startDate: Date
  endDate: Date
  offer: string
  promotedOffer: OfferResponseDto | AuctionResponseDto
}

export type PromotionCreateDto = {
  startDate: Date
  endDate: Date
  promotedOfferId: string
}

/* ----------------------------------- User ----------------------------------- */

export interface Metadata {
  lastSignInTime: string
  creationTime: string
}

export interface ProviderData {
  uid: string
  email: string
  providerId: string
}

export interface FirebaseUser {
  uid: string
  email: string
  emailVerified: boolean
  disabled: boolean
  metadata: Metadata
  tokensValidAfterTime: string
  providerData: ProviderData[]
}

/* ----------------------------- Offer get filter ----------------------------- */

export enum OfferColumnEnum {
  TITLE = 'title',
  PRICE = 'price',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
  SCORE = 'score',
  NONE = 'none',
  AVAILABILITY = 'availability',
  LATEST_BOOKMARK = 'latestBookmark',
}

export enum SortTypeEnum {
  ASCENDING = 'asc',
  DESCENDING = 'desc',
}

export class OfferFilter {
  q? = ''
  limit? = 10
  skip? = 0
  sortType?: SortTypeEnum = SortTypeEnum.ASCENDING
  sortField?: OfferColumnEnum = OfferColumnEnum.CREATED_AT
  status?: OfferStatus[]
  goodsGroups?: string[]
  companies?: string[]
  minPrice?: number
  maxPrice?: number
  creator?: string
  ids?: string[]
  condition?: Condition | Condition[]
  startDate?: string
  endDate?: string
  availableFrom?: string
  availableTill?: string
  __t?: 'Offer' | 'Auction'
  publishDateOperator?: string
  publishDate?: string
  offerType?: string[]
  quantity?: number
  zipCode?: string
  city?: string
  locationRadius?: number
  materialIds?: string
  language?: Language
}

/* ----------------------------- Goods groups ----------------------------- */

export type GoodsGroupResponseDto = {
  id: string
  nameDe: string
  nameFr: string
  nameIt: string
  nameEn: string
  parent?: string
}

export type GoodsGroupCreateDto = {
  nameDe: string
  nameFr: string
  nameIt: string
  nameEn: string
  parent?: string
}

export type GoodsGroupUpdateDto = {
  nameDe?: string
  nameFr?: string
  nameIt?: string
  nameEn?: string
  parent?: string
}

/* ----------------------------- Role Requests ----------------------------- */

export enum Role {
  COMPANY_MANAGER = 'CompanyManager',
  CONTENT_MANAGER = 'ContentManager',
  SYSTEM_ADMIN = 'SystemAdmin',
  OFFER_PROVIDER = 'OfferProvider',
  AUCTION_BIDDER = 'AuctionBidder',
}

export type RoleRequestCreateDto = {
  role: Role
  uid: string
}

export type RoleRequestResponseDto = {
  id: string
  role: Role
  uid: string
}

/* ----------------------------- Search Types ----------------------------- */

export type OfferAutocompleteResponseDto = {
  suggestions: Translations[]
}

export class SearchFilter {
  q = ''
  limit? = '10'
  skip? = 0
  sortType?: SortTypeEnum = SortTypeEnum.DESCENDING
  sortField?: OfferColumnEnum = OfferColumnEnum.NONE
}

/* ----------------------------- Companies ----------------------------- */

export type CompanyResponseDto = {
  id: string
  name: string
  mailDomains: string[]
  createdAt: string
  updatedAt: string
  termsAndConditions?: Translations
  invalidDeliveryDates?: InvalidDeliveryDate[]
  incoterms?: Incoterms[]
}
export type Incoterms = {
  transportType: TransportType
  incotermInland: string
  incotermAbroad: string
}

export type CompanyCreateDto = {
  name: string
  mailDomains: string[]
  termsAndConditions: Translations
  invalidDeliveryDates?: InvalidDeliveryDate[]
  incoterms?: Incoterms[]
}

export type CompanyUpdateDto = {
  name?: string
  mailDomains?: string[]
  termsAndConditions?: Translations
  invalidDeliveryDates?: InvalidDeliveryDate[]
  incoterms?: Incoterms[]
}

export type InvalidDeliveryDate = {
  startDate: string
  endDate: string
}
/* ----------------------------- News ----------------------------- */

export type News = {
  id: string
  createdAt: string
  updatedAt: string
  title: Translations
  content: Translations
  creator: string
  modifier: string
  thumbnailUrl?: File
}

export type CreateNewsDto = {
  title: Translations
  content: Translations
}

export type UpdateNewsDto = {
  title: Translations
  content: Translations
}

export type NewsPaginationResponseDto = {
  results: News[]
  count: number
}

export enum NewsFilterLanguage {
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  EN = 'en',
}

export enum NewsFilterSortField {
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}

export enum NewsFilterSortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type NewsFilter = {
  languages?: NewsFilterLanguage[]
  sortField?: NewsFilterSortField
  sortType?: NewsFilterSortType
  modifier?: string
  creator?: string
  limit?: number
  skip?: number
}

/* ----------------------------- User ----------------------------- */

export type User = {
  uid: string
  displayName: string
  email: string
  customClaims: {
    roles?: Role[]
  }
  emailVerified: boolean
}

export type UserQuery = {
  roles?: Role[]
  customerId?: string[]
}

export type UserEmailVerifiedPutDto = {
  emailVerified: boolean
}

/* ----------------------------- User Informations ----------------------------- */

export type UserInformation = {
  id: string
  salutation?: string
  firstName?: string
  lastName?: string
  telephone?: string
  mobile?: string
  email: string
  company?: string
  address?: Address
  organizationUnit?: string
}

export type CreateUserInformationDto = {
  salutation: string
  firstName: string
  lastName: string
  telephone: string
  mobile: string
  company: string
  address: Address
  organizationUnit?: string
}

/* ----------------------------- Other ----------------------------- */

export type Translations = {
  de: string
  fr: string
  it: string
  en: string
}

export type Address = {
  address: string
  mailBox: string
  mailBoxZipCode?: string
  mailBoxCity?: string
  zipCode: string
  city: string
  country: string
}

/* ----------------------------- Legal Info ----------------------------- */

export type LegalInfo = {
  _id: string
  name: string
  text: Translations
}

export type LegalInfoUpdateDto = {
  text: Translations
}

/* ----------------------------- Newsletter Sign Ups ----------------------------- */

export type NewsletterSignUp = {
  id: string
  createdAt: string
  updatedAt: string
  firstName: string
  lastName: string
  email: string
  language: string
  salutation: string
}

export type CreateNewsletterSignUpDto = {
  firstName: string
  lastName: string
  email: string
  language: string
  salutation: string
}

export type NewsletterSignUpResponseDto = {
  results: NewsletterSignUp[]
  count: number
}

/* ----------------------------- Hero Section ----------------------------- */

export type HeroSection = {
  id: string
  themeId: string
  createdAt: string
  updatedAt: string
  title: Translations
  teaserText: Translations
  linkUrl: Translations
  linkTitle: Translations
  startDate: Date
  endDate: Date
  image: File
  creator: string
  modifier: string
}

export type CreateHeroSectionDto = {
  themeId: string
  title: Translations
  teaserText: Translations
  linkUrl: Translations
  linkTitle: Translations
  startDate: Date
  endDate: Date
  image: File
}

export type UpdateHeroSectionDto = {
  themeId: string
  startDate: Date
  endDate: Date
  title: Translations
  teaserText: Translations
  linkUrl: Translations
  linkTitle: Translations
  image: File
}

export type HeroSectionPaginationResponseDto = {
  results: HeroSection[]
  count: number
}

export enum HeroSectionFilterLanguage {
  DE = 'de',
  FR = 'fr',
  IT = 'it',
  EN = 'en',
}

export enum HeroSectionFilterSortField {
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}

export enum HeroSectionFilterSortType {
  ASC = 'asc',
  DESC = 'desc',
}

export type HeroSectionFilter = {
  languages?: HeroSectionFilterLanguage[]
  sortField?: HeroSectionFilterSortField
  sortType?: HeroSectionFilterSortType
  modifier?: string
  creator?: string
  limit?: number
  skip?: number
  startDate?: string
  endDate?: string
  themeId?: string
}
/* ----------------------------- Feature Flags ----------------------------- */

export type FeatureFlagCreateDto = {
  name: string
  isActive: boolean
}

export type FeatureFlagUpdateDto = {
  isActive: boolean
}

export type FeatureFlag = {
  _id: string
  __v: number
  id: string
  name: string
  isActive: boolean
}

export type FeatureFlagResponseDto = {
  results: FeatureFlag[]
  count: number
}

/* ----------------------------- Checkouts ----------------------------- */
export type CheckoutCreateDto = {
  billingReference: string
  debitorNumber: string
  shoppingCartItems: CreateCheckoutShoppingCartItemDto[]
  transport:
    | TransportDefault
    | TransportConstruction
    | TransportRail
    | TransportPickup
}

export type CreateCheckoutShoppingCartItemDto = {
  offerId: string
  amount: number
}

export type Checkout = {
  id: string
  _id: string
  __v: number
  createdAt: string
  updatedAt: string
  orderId: string
  userId: string
  shoppingCartItems: ShoppingCartItem[]
  ordererData: UserInformation
  customerData: Customer
  billingReference: string
  debitorNumber: string
  checkoutStatus: CheckoutStatus
  lastModifier: string
  transport:
    | TransportDefault
    | TransportConstruction
    | TransportRail
    | TransportPickup
}

export type ShoppingCartItemOffer = {
  offer: OfferResponseDto
  offersChildren: ShoppingCartItemOffer[]
}

export type ShoppingCartItem = {
  shoppingCartItemOffer: ShoppingCartItemOffer
  amount: number
}

export enum TransportType {
  Default = 'Default',
  Construction = 'Construction',
  Rail = 'Rail',
  PickUp = 'PickUp',
}

export type TransportDefault = {
  type: TransportType.Default
  companyName: string
  additionalInformationCompany: string
  additionalInformation: string
  contact?: string
  address: Address
  wishDelivery: string
  eoriNumber: string
}

export type TransportConstruction = {
  type: TransportType.Construction
  companyName: string
  constructionSiteName: string
  contact: string
  address: Address
  hasCraneOnSite: boolean
  wishDelivery: string
  eoriNumber: string
}

export type TransportRail = {
  type: TransportType.Rail
  companyName: string
  additionalInformationCompany: string
  additionalInformation: string
  contact: string
  address: Address
  hasCraneOnSite: boolean
  wishDelivery: string
  eoriNumber: string
}

export type TransportPickup = {
  type: TransportType.PickUp
  wishDelivery: string
}

export type CheckoutFilter = {
  limit?: number
  skip?: number
  q?: string
  checkoutStatus?: CheckoutStatus[]
  userId?: string
  sortType?: CheckoutSortType
  sortField?: CheckoutSortField
}

export type CheckoutPageResultDto = {
  results: Checkout[]
  count: number
}

export type CheckoutStatusPatchDto = {
  checkoutStatus: CheckoutStatus
}

export enum CheckoutStatus {
  Created = 'Created',
  Processed = 'Processed',
}

export enum CheckoutSortField {
  CHECKOUT_STATUS = 'checkoutStatus',
  UPDATED_AT = 'updatedAt',
  CREATED_AT = 'createdAt',
}

export enum CheckoutSortType {
  ASCENDING = 'asc',
  DESC = 'desc',
}

/* ----------------------------- Customers ----------------------------- */

export type Debitor = {
  debitorNumber: string
  address: Address
  additionalInformationCompany: string
  additionalInformation: string
  mainDebitor: boolean
}

export type Customer = {
  _id: string
  __v: number
  id: string
  createdAt: string
  updatedAt: string
  name: string
  members: string[]
  debitors: Debitor[]
}

export type CustomersResponseDto = {
  results: Customer[]
  count: number
}

export type CustomersPostDto = {
  name: string
  members: string[]
  debitors: Debitor[]
}

export type CustomersPatchDto = {
  name?: string
  members?: string[]
  debitors?: Debitor[]
}

export type CustomerMembersPatchDto = {
  members: string[]
}

export type CustomerMembersDeleteDto = {
  members: string[]
}

export type CustomerDebitorsPatchDto = {
  debitors: Debitor[]
}

export type CustomerDebitorsDeleteDto = {
  debitorNumbers: string[]
}

/* ----------------------------- Feedbacks ----------------------------- */

export type Feedback = {
  _id: string
  __v: number
  id: string
  createdAt: string
  updatedAt: string
  email?: string
  rating: number
  positive?: string
  negative?: string
  comment?: string
}

export type FeedbackCreateDto = {
  email?: string
  rating: number
  positive?: string
  nagative?: string
  comment?: string
}

export type FeedbacksResponseDto = {
  results: Feedback[]
  count: number
}

/* ----------------------------- Edit-Text ----------------------------- */

export type EditableInfo = {
  id: string
  _id: string
  __v: number
  name: string
  text: Translations
}

export type EditableInfoPut = {
  text: Translations
}

/* ----------------------------- Search Subscriptions ----------------------------- */

export type SearchSubscription = {
  id: string
  _id: string
  __v: string
  createdAt: string
  updatedAt: string
  title: string
  subscriberUserId: string
  searchSubscriptionFilter?: SearchSubscriptionFilter
}

export class SearchSubscriptionFilter {
  title?: string
  skip?: number
  limit?: number
  status?: OfferStatus[]
  goodsGroupId?: string
  minPrice?: number
  maxPrice?: number
  condition?: Condition[]
  textSearch?: string
  startDate?: Date
  endDate?: Date
  availableFrom?: Date
  availableTill?: Date
}

export type SearchSubscriptionCreateDto = {
  title: string
  subscriberUserId: string
  searchSubscriptionFilter?: SearchSubscriptionFilter
}

export type SearchSubscriptionPatchDto = {
  title: string
  subscriperUserId: string
  searchSubscriptionFilter?: SearchSubscriptionFilter
}

export type SearchSubscriptionResponseDto = {
  results: SearchSubscription[]
  count: number
}

/* ----------------------------- Themes ----------------------------- */

export type Theme = {
  id: string
  _id: string
  __v: string
  createdAt: string
  updatedAt: string
  host: string
  brandHue: string
  brandSaturation: string
  brandLightness: string
  companyId: string
  homePageTitle: Translations
  featureFlags: FeatureFlag[]
  companies: string[]
  siteTitle: Translations
  impressum: Translations
}

export type ThemeFilter = {
  host?: string
  companyId?: string
}

export type ThemePutDto = {
  host: string
  brandHue: string
  brandSaturation: string
  brandLightness: string
  companyId: string
  homePageTitle: Translations
  featureFlags: FeatureFlag[]
  companies: string[]
  siteTitle: Translations
  impressum: Translations
}

export type ThemeResponseDto = {
  results: Theme[]
  count: number
}

/* ----------------------------- Logs ----------------------------- */

export type Log = {
  _id: string
  __v: number
  message: string
  id: string
  createdAt: string
  updatedAt: string
}

export type LogsResponseDto = {
  results: Log[]
  count: number
}

export type LogFilter = {
  startDate?: string
  logMessages?: string
  skip?: number
  limit?: number
}

/* ----------------------------- Bookmarks ----------------------------- */

export type Bookmark = {
  _id: string
  title: string
  offers: OfferResponseDto[]
  updatedAt: string
}

export type BookmarkRequestDto = {
  title: string
}

export type SingleBookmark = {
  _id: string
  title: string
  offerIds: string[]
  updatedAt: string
}

export type CheckedBookmarks = {
  _id: string
  title: string
  checked: boolean
}

export type BookmarkOffer = {
  id: string
  imgUrl: string
  title: string
  location: string
  quantity: number | undefined
  quantityUnit: Unit | undefined
  availableFrom: Date | undefined
  availableTill: Date | undefined
  price: {
    value: string
    label?: string
    bidsLength?: number
  }[]
  tags: string[]
  link: string
  cardCornerLine1: string
  cardCornerLine2: string
  auctionStartDate: string
  auctionEndDate: string
  logoUrl: string
  referenceId: string | undefined
  status: OfferStatus
  creator: string
  showUnitPrice: boolean
  publishDate?: string
  goodsGroupId: string
}

export type LocationZipCodeDTO = {
  data: LocationZipCode[]
  count: number
}

export type LocationZipCode = {
  _id: string
  localityName: string
  zipCode: string
  longitude: number
  latitude: number
}
export type LocationZipCodes = {
  data: LocationZipCode[]
  count: number
}

/* ----------------------------- Levels ----------------------------- */

export type Level = {
  _id: string
  id: string
  __v: number
  userId: string
  xp: number
  level: number
}

export type LevelFilter = {
  limit?: number
  skip?: number
  isPublic?: boolean
}

export type LevelsResponseDto = {
  results: Level[]
  count: number
}

/* ----------------------------- Material ----------------------------- */

export type DropdownItem = {
  id: string
  nameDe: string
  nameFr: string
  nameIt: string
  nameEn: string
  children: DropdownItem[]
  areChildrenOpen?: boolean
}

export type UnmappedItem = {
  id: string
  parent?: string
  [key: string]: unknown
}

export type PromotionOffer = {
  id: string
  imgUrl: string
  title: string
  price: {
    value: string
    label?: string
    bidsLength?: number
  }[]
  link: string
  cardCornerLine1: string
  cardCornerLine2: string
  auctionStartDate: string
  auctionEndDate: string
  logoUrl: string
  publishDate?: string
  creator: string
  companyName?: string
}

export type Material = {
  id: string
  nameDe: string
  nameFr: string
  nameIt: string
  nameEn: string
  createdAt: string
  updatedAt: string
  parent?: string
}

export type CommentDto = {
  offerId?: string
  comment: string
  parent?: string
  author?: string
}

export type Comment = {
  _id: string
  author: string
  offerId: string
  comment: string
  children: Comment[]
  parent?: string
  createdAt: string
  updatedAt: string

  isReplying: boolean
  areChildrenShown: boolean
}

export type CommentResponseDto = {
  count: number
  results: Comment[]
}

export type FaqCategoryDto = {
  id: string
  title: Translations
  sortOrder: number
  faqs: {
    id?: string
    title: Translations
    information: Translations
    companyId?: string
    sortOrder: number
  }[]
}

export type FaqCategoriesResponseDto = {
  results: FaqCategoryDto[]
  count: number
}

export enum DeactivationReason {
  Sold = 'Sold',
  Other = 'Other',
}

export type FaqCategorySortOrderDto = {
  id: string
  sortOrder: number
}
