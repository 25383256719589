import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import {
  AuctionResponseDto,
  DeactivationReason,
  OfferAutocompleteResponseDto,
  OfferCreateDto,
  OfferFilter,
  OfferPaginationResponseDto,
  OfferResponseDto,
  OfferUpdateDto,
} from '../schemas'

export default {
  get: async (
    params: OfferFilter,
    token?: string,
  ): Promise<OfferPaginationResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers`
    const urlWithParams = new URL(url)

    urlWithParams.search = objectToSearchParams({ ...params }).toString()
    console.log('get offers from: ', urlWithParams.toString())
    const response = await fetch(
      urlWithParams.toString(),
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {},
    )
    if (!response.ok) throw new Error(response.statusText)
    const data: OfferPaginationResponseDto = await response.json()
    return data
  },
  autocomplete: async (query: { q: string; limit?: string }) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/autocomplete`
    const urlWithParams = new URL(url)
    urlWithParams.search = new URLSearchParams(query).toString()

    const response = await fetch(urlWithParams.toString())
    if (!response.ok) throw new Error(response.statusText)

    const data: OfferAutocompleteResponseDto = await response.json()
    return data
  },
  new: {
    post: async (
      body: OfferCreateDto,
      token: string,
    ): Promise<OfferResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers`
      console.log(
        'post offer to: ',
        `${import.meta.env.VITE_RESALE_API_HOST}/offers`,
      )
      const form = new FormData()
      form.append('title', JSON.stringify(body.title))
      form.append('description', JSON.stringify(body.description))
      form.append('price', body.price?.toString() || '')
      form.append('goodsGroup', body.goodsGroupId)
      form.append('currency', body.currency.toString())

      if (body.publishDate && body.publishDate.toString() !== 'Invalid Date') {
        form.append('publishDate', body.publishDate.toISOString())
      }

      form.append('thumbnailIndex', JSON.stringify(body.thumbnailIndex))

      if (body.images.length > 0) {
        body.images.forEach((file) => {
          form.append('images', file)
        })
      }
      if (body.documents.length > 0) {
        body.documents.forEach((file) => {
          form.append('documents', file)
        })
      }

      form.append('contact', JSON.stringify(body.contact))

      form.append('links', JSON.stringify(body.links))

      form.append('quantity', body.quantity?.toString() || '')
      if (body.quantityUnit) {
        form.append('quantityUnit', body.quantityUnit?.toString())
      }
      form.append('zipCode', body.zipCode?.toString() || '')
      form.append('city', body.city?.toString() || '')
      if (body.condition) {
        form.append('condition', body.condition?.toString())
      }
      if (
        body.availableFrom &&
        body.availableFrom.toString() !== 'Invalid Date'
      ) {
        form.append('availableFrom', body.availableFrom?.toISOString())
      }
      if (
        body.availableTill &&
        body.availableTill.toString() !== 'Invalid Date'
      ) {
        form.append('availableTill', body.availableTill?.toISOString())
      }
      form.append('manufacturer', body.manufacturer?.toString() || '')
      form.append('constructionYear', body.constructionYear?.toString() || '')
      form.append('materialIds', JSON.stringify(body.materialIds))
      form.append('length', body.length?.toString() || '')
      if (body.lengthUnit) {
        form.append('lengthUnit', body.lengthUnit?.toString())
      }
      form.append('width', body.width?.toString() || '')
      if (body.widthUnit) {
        form.append('widthUnit', body.widthUnit?.toString())
      }
      form.append('height', body.height?.toString() || '')
      if (body.heightUnit) {
        form.append('heightUnit', body.heightUnit?.toString())
      }
      form.append('weight', body.weight?.toString() || '')
      if (body.weightUnit) {
        form.append('weightUnit', body.weightUnit?.toString())
      }
      form.append('visibility', JSON.stringify(body.visibility))
      form.append('contactPerson', JSON.stringify(body.contactPerson))

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: OfferResponseDto = await response.json()
      return data
    },
  },
  id: {
    get: async (
      id: string,
      token?: string,
    ): Promise<OfferResponseDto | AuctionResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}`
      console.log('get offer from: ', url)
      const response = await fetch(
        url,
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {},
      )
      if (!response.ok) throw new Error(response.statusText)

      const data: OfferResponseDto = await response.json()
      return data
    },
    patch: async (
      id: string,
      body: OfferUpdateDto,
      token: string,
    ): Promise<OfferResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}`
      console.log('patch offer to: ', url)
      const form = new FormData()
      if (body?.title) {
        form.append('title', JSON.stringify(body.title))
      }
      if (body?.description) {
        form.append('description', JSON.stringify(body.description))
      }
      form.append('price', body.price?.toString() || '')
      if (body?.price && body?.currency) {
        form.append('currency', body.currency.toString())
      }

      form.append('thumbnailIndex', JSON.stringify(body.thumbnailIndex))

      if (body.images && body.images.length > 0) {
        body.images.forEach((file) => {
          form.append('images', file)
        })
      }
      if (body.documents && body.documents.length > 0) {
        body?.documents.forEach((file) => {
          form.append('documents', file)
        })
      }
      if (body?.goodsGroupId) {
        form.append('goodsGroup', body.goodsGroupId)
      }
      if (body.publishDate && body.publishDate.toString() !== 'Invalid Date') {
        form.append('publishDate', body.publishDate.toISOString())
      }

      form.append('contact', JSON.stringify(body.contact))

      form.append('links', JSON.stringify(body.links))

      form.append('quantity', body.quantity?.toString() || '')
      if (body.quantityUnit) {
        form.append('quantityUnit', body.quantityUnit?.toString())
      }
      form.append('zipCode', body.zipCode?.toString() || '')
      form.append('city', body.city?.toString() || '')
      if (body.condition) {
        form.append('condition', body.condition?.toString())
      }
      if (
        body.availableFrom &&
        body.availableFrom.toString() !== 'Invalid Date'
      ) {
        form.append('availableFrom', body.availableFrom?.toISOString())
      }
      if (
        body.availableTill &&
        body.availableTill.toString() !== 'Invalid Date'
      ) {
        form.append('availableTill', body.availableTill?.toISOString())
      }
      form.append('manufacturer', body.manufacturer?.toString() || '')
      form.append('constructionYear', body.constructionYear?.toString() || '')
      form.append('materialIds', JSON.stringify(body.materialIds))
      form.append('length', body.length?.toString() || '')
      if (body.lengthUnit) {
        form.append('lengthUnit', body.lengthUnit?.toString())
      }
      form.append('width', body.width?.toString() || '')
      if (body.widthUnit) {
        form.append('widthUnit', body.widthUnit?.toString())
      }
      form.append('height', body.height?.toString() || '')
      if (body.heightUnit) {
        form.append('heightUnit', body.heightUnit?.toString())
      }
      form.append('weight', body.weight?.toString() || '')
      if (body.weightUnit) {
        form.append('weightUnit', body.weightUnit?.toString())
      }
      form.append('visibility', JSON.stringify(body.visibility))
      form.append('contactPerson', JSON.stringify(body.contactPerson))

      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: OfferResponseDto = await response.json()
      return data
    },
    delete: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}`
      console.log('delete offer: ', url)
      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      return response
    },
    approve: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}/approve`
      console.log('approve offer: ', url)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      return response
    },
    decline: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}/decline`
      console.log('decline offer: ', url)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      return response
    },
    activate: async (id: string, token: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/offers/${id}/activate`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)
      return response
    },
    deactivate: async (
      id: string,
      reason: DeactivationReason,
      token: string,
    ) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/offers/${id}/deactivate`
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ reason }),
      })
      if (!response.ok) throw new Error(response.statusText)
      return response
    },
    thumbnail: async (id: string, token?: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/offers/${id}/thumbnail`
      const headers: HeadersInit = {}
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }
      const response = await fetch(url, {
        method: 'GET',
        headers,
      })
      if (!response.ok) throw new Error(response.statusText)

      const data = await response.text()
      return data
    },
    images: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/offers/${id}/images`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data = await response.json()

      return data
    },
    documents: async (id: string, token: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/offers/${id}/documents`
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data = await response.text()
      const extractedStringArray = data
        .substring(1, data.length - 1)
        .replace(/['"]+/g, '')
        .split(',')

      return extractedStringArray
    },
  },
}
