import { useCompanyTheme } from '../hooks/useCompanyTheme'

export async function setupCookieConstent() {
  const companyTheme = useCompanyTheme()

  if (!companyTheme.getCookieBannerFeatureFlag.value) return

  const OtAutoBlock = document.createElement('script')
  OtAutoBlock.setAttribute('type', 'text/javascript')
  OtAutoBlock.setAttribute(
    'src',
    `https://cdn.cookielaw.org/consent/${
      import.meta.env.VITE_COOKIE_CONSENT_ONE_TRUST_DATA_DOMAIN_SCRIPT_ID
    }/OtAutoBlock.js`,
  )

  const otSDKStub = document.createElement('script')
  otSDKStub.setAttribute('type', 'text/javascript')
  otSDKStub.setAttribute(
    'src',
    'https://cdn.cookielaw.org/scripttemplates/otSDKStub.js',
  )
  otSDKStub.setAttribute('data-document-language', 'true')
  otSDKStub.setAttribute('charset', 'UTF-8')
  otSDKStub.setAttribute(
    'data-domain-script',
    `${import.meta.env.VITE_COOKIE_CONSENT_ONE_TRUST_DATA_DOMAIN_SCRIPT_ID}`,
  )

  const OptanonWrapper = document.createElement('script')
  OptanonWrapper.setAttribute('type', 'text/javascript')
  const code = 'function OptanonWrapper(){}'
  OptanonWrapper.appendChild(document.createTextNode(code))

  document.head.appendChild(OtAutoBlock)
  document.head.appendChild(otSDKStub)
  document.head.appendChild(OptanonWrapper)
}
