import { getAuth, updateProfile } from '@firebase/auth'
import { deleteObject, getStorage, ref, uploadBytes } from '@firebase/storage'
import imageCompression from 'browser-image-compression'

export default {
  me: {
    async uploadProfilePicture(file: File): Promise<string | void> {
      const auth = getAuth()
      if (auth.currentUser) {
        const storage = getStorage()

        const imageRef = ref(
          storage,
          'users/' + auth.currentUser.uid + '/profilePicture',
        )

        const compressedFile = await imageCompression(file, {
          initialQuality: 0.3,
          useWebWorker: false,
        })
        const uploadedImage = await uploadBytes(imageRef, compressedFile)
        const bucket = uploadedImage.metadata.bucket
        const path = uploadedImage.ref.fullPath.replaceAll('/', '%2F')

        const publicUrl = `https://firebasestorage.googleapis.com/v0/b/${bucket}/o/${path}?alt=media`

        await updateProfile(auth.currentUser, { photoURL: publicUrl }).catch(
          (e) => {
            console.log(e)
          },
        )
        return publicUrl
      }
    },
    async removeProfilePicture(): Promise<void> {
      const auth = getAuth()
      if (auth.currentUser?.photoURL) {
        await updateProfile(auth.currentUser, { photoURL: '' })

        const storage = getStorage()
        const oldImageRef = ref(
          storage,
          'users/' + auth.currentUser.uid + '/profilePicture',
        )
        return deleteObject(oldImageRef)
      }
    },
    async updateDisplayName(newDisplayName: string): Promise<void> {
      const auth = getAuth()
      if (auth.currentUser) {
        await updateProfile(auth.currentUser, {
          displayName: newDisplayName,
        }).catch((e: Error) => {
          console.log(e)
        })
      }
    },
  },
}
