import axios from 'axios'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import { Theme, ThemeFilter, ThemePutDto, ThemeResponseDto } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/themes`

export default {
  get: async (params: ThemeFilter, token?: string) => {
    const urlWithParams = new URL(baseUrl)

    urlWithParams.search = objectToSearchParams({ ...params }).toString()

    const { data } = await axios.get<ThemeResponseDto>(
      urlWithParams.toString(),
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {},
    )

    return data
  },
  id: {
    get: async (id: string, token: string) => {
      const url = `${baseUrl}/${id}`

      const { data } = await axios.get<Theme>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    put: async (id: string, body: ThemePutDto, token: string) => {
      const url = `${baseUrl}/${id}`

      const { data } = await axios.put<Theme>(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return data
    },
    delete: async (id: string, token: string) => {
      const url = `${baseUrl}/${id}`

      const { data } = await axios.delete<Theme>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return data
    },
    favicon: {
      put: async (id: string, image: File, token: string) => {
        const url = `${baseUrl}/${id}/favicon`

        const body = new FormData()
        body.append('file', image)

        const { data } = await axios.put<string>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
      get: async (id: string) => {
        const url = `${baseUrl}/${id}/favicon`
        const { data } = await axios.get<string>(url, {})
        return data
      },
    },
    logo: {
      put: async (id: string, image: File, token: string) => {
        const url = `${baseUrl}/${id}/logo`
        const body = new FormData()
        body.append('file', image)
        const { data } = await axios.put<string>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
      get: async (id: string) => {
        const url = `${baseUrl}/${id}/logo`
        const { data } = await axios.get<string>(url)
        return data
      },
    },
  },
}
