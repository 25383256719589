<template>
  <router-view :key="$route.path" />
  <RsToast />
  <RsAppEnv />
</template>

<script setup lang="ts">
import { useLanguage } from './hooks/useLanguage'
import { useI18n } from 'vue-i18n'
import { defineAsyncComponent, watch } from 'vue'
import { useRoute } from 'vue-router'
import RsToast from './components/RsToast.vue'
import { useSiteTitle } from './hooks/useSiteTitle'

const RsAppEnv = defineAsyncComponent(() => import('./components/RsAppEnv.vue'))

const { currentLanguage, i18nInstance } = useLanguage()
const route = useRoute()
const i18n = useI18n()
const siteTitle = useSiteTitle()

watch(currentLanguage, () => {
  if (currentLanguage.value) {
    i18n.locale.value = currentLanguage.value
    const defaultTitle = siteTitle.value
    if (route.meta.title) {
      document.title = `${i18nInstance.global.t(
        route.meta.title as string,
      )} - ${defaultTitle}`
    }
  }
})
</script>
