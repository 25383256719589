import axios from 'axios'
import {
  Customer,
  CustomerDebitorsDeleteDto,
  CustomerDebitorsPatchDto,
  CustomerMembersDeleteDto,
  CustomerMembersPatchDto,
  CustomersPatchDto,
  CustomersPostDto,
  CustomersResponseDto,
  Debitor,
} from '../schemas'

export default {
  get: async (token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/customers`
    const { data } = await axios.get<CustomersResponseDto>(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  post: async (body: CustomersPostDto, token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/customers`
    const { data } = await axios.post<Customer>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  id: {
    delete: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/customers/${id}`
      const { data } = await axios.delete<Customer>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    get: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/customers/${id}`
      const { data } = await axios.get<Customer>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    patch: async (id: string, body: CustomersPatchDto, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/customers/${id}`
      const { data } = await axios.patch<Customer>(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    debitors: {
      patch: async (
        id: string,
        body: CustomerDebitorsPatchDto,
        token: string,
      ) => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/customers/${id}/debitors`
        const { data } = await axios.patch<Debitor[]>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
      delete: async (
        id: string,
        debitorNumbers: CustomerDebitorsDeleteDto,
        token: string,
      ) => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/customers/${id}/debitors`
        const { data } = await axios.delete<Debitor[]>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: debitorNumbers,
        })
        return data
      },
      get: async (id: string, debitorNumber: string, token: string) => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/customers/${id}/debitors/${debitorNumber}`

        const { data } = await axios.get<Debitor>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
    members: {
      patch: async (
        id: string,
        body: CustomerMembersPatchDto,
        token: string,
      ) => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/customers/${id}/members`
        const { data } = await axios.patch<string[]>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
      delete: async (
        id: string,
        members: CustomerMembersDeleteDto,
        token: string,
      ) => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/customers/${id}/members`
        const { data } = await axios.delete<string[]>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
          data: members,
        })
        return data
      },
    },
  },
}
