import axios from 'axios'
import { LocationZipCodeDTO, LocationZipCodes } from '../schemas'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/location-zipcodes`

export default {
  get: async (
    params: { skip?: number; limit?: number; q?: string } = {
      skip: 0,
      limit: 0,
      q: '',
    },
  ) => {
    try {
      const urlWithParams = new URL(baseUrl)
      urlWithParams.search = objectToSearchParams(params).toString()

      const { data } = await axios.get<LocationZipCodeDTO>(
        urlWithParams.toString(),
      )

      return data
    } catch (error) {
      console.error('Error fetching location data:', error)
      throw error
    }
  },
  autocomplete: async (q: string) => {
    try {
      const response = await axios.get<LocationZipCodes>(
        `${baseUrl}/autocomplete`,
        {
          params: { q },
        },
      )
      return response.data
    } catch (error) {
      console.error('Error fetching autocomplete data:', error)
      throw error
    }
  },
  validateLocation: async (zipCode: string, city: string): Promise<boolean> => {
    try {
      const response = await axios.get<boolean>(`${baseUrl}/validate`, {
        params: { zipCode, city },
      })
      return response.data
    } catch (error) {
      console.error('Error validating location:', error)
      return false
    }
  },
  delete: async (id: string, token: string) => {
    try {
      await axios.delete<void>(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    } catch (error) {
      console.error('Error deleting location:', error)
      throw error
    }
  },
}
