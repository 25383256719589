export const objectToSearchParams = (params: SearchParams): URLSearchParams => {
  const searchParams = new URLSearchParams()

  for (const param in params) {
    const value = params[param]
    if (value !== undefined) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          searchParams.append(param, item)
        })
      } else {
        // Otherwise, treat it as a single value
        searchParams.append(param, String(value))
      }
    }
  }

  return searchParams
}

type SearchParams = {
  [key: string]: string | boolean | number | string[]
}
