import axios from 'axios'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import {
  HeroSection,
  CreateHeroSectionDto,
  HeroSectionFilter,
  HeroSectionPaginationResponseDto,
  UpdateHeroSectionDto,
} from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/hero-section`

export default {
  post: async (
    payload: CreateHeroSectionDto,
    token: string,
  ): Promise<HeroSection> => {
    const url = `${baseUrl}`
    const headers: HeadersInit = {}
    headers.Authorization = `Bearer ${token}`
    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json'

    const toBase64 = (file: Blob) =>
      new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onloadend = () =>
          resolve(reader.result?.toString().split(',')[1])
        reader.onerror = (error) => reject(error)
      })

    const body = {
      themeId: payload.themeId,
      title: payload.title,
      teaserText: payload.teaserText,
      linkTitle: payload.linkTitle,
      linkUrl: payload.linkUrl,
      startDate: payload.startDate,
      endDate: payload.endDate,
      image: await toBase64(payload.image),
    }

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body: JSON.stringify(body),
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: HeroSection = await response.json()

    return data
  },
  get: async (
    params?: HeroSectionFilter,
    token?: string,
    options?: { noCache?: boolean },
  ): Promise<HeroSectionPaginationResponseDto> => {
    const urlWithParams = new URL(baseUrl)

    urlWithParams.search = params ? objectToSearchParams(params).toString() : ''

    const headers: HeadersInit = {}
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }
    if (options?.noCache) {
      headers['Cache-Control'] = 'co-cache'
      headers['Pragma'] = 'no-cache'
      headers['Expires'] = '0'
    }

    const { data } = await axios.get<HeroSectionPaginationResponseDto>(
      urlWithParams.toString(),
      { headers },
    )
    return data
  },
  id: {
    get: async (id: string, token?: string): Promise<HeroSection> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }

      const response = await fetch(url, { headers })
      if (!response.ok) throw new Error(response.statusText)

      const data: HeroSection = await response.json()
      return data
    },
    patch: async (
      id: string,
      payload: UpdateHeroSectionDto,
      token: string,
    ): Promise<HeroSection> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      headers.Authorization = `Bearer ${token}`
      headers.Accept = 'application/json'
      headers['Content-Type'] = 'application/json'

      const toBase64 = (file: Blob) =>
        new Promise((resolve, reject) => {
          const reader = new FileReader()
          reader.readAsDataURL(file)
          reader.onloadend = () =>
            resolve(reader.result?.toString().split(',')[1])
          reader.onerror = (error) => reject(error)
        })

      const body = {
        themeId: payload.themeId,
        title: payload.title,
        teaserText: payload.teaserText,
        linkTitle: payload.linkTitle,
        linkUrl: payload.linkUrl,
        startDate: payload.startDate,
        endDate: payload.endDate,
        image: await toBase64(payload.image),
      }

      const response = await fetch(url, {
        method: 'PATCH',
        headers,
        body: JSON.stringify(body),
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: HeroSection = await response.json()
      return data
    },
    delete: async (id: string, token: string): Promise<void> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      headers.Authorization = `Bearer ${token}`
      headers.Accept = 'application/json'
      headers['Content-Type'] = 'application/json'

      const response = await fetch(url, {
        method: 'DELETE',
        headers,
      })
      if (!response.ok) throw new Error(response.statusText)
    },
    thumbnail: {
      put: async (id: string, image: File, token: string): Promise<string> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`

        const body = new FormData()
        body.append('file', image)

        const response = await fetch(url, {
          method: 'PUT',
          headers,
          body,
        })

        if (!response.ok) throw new Error(response.statusText)

        const data: string = await response.text()
        return data
      },
      get: async (id: string, token?: string): Promise<string> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        if (token) {
          headers.Authorization = `Bearer ${token}`
        }

        const response = await fetch(url, { headers })
        if (!response.ok) throw new Error(response.statusText)

        const data: string = await response.text()
        return data
      },
      delete: async (id: string, token: string): Promise<void> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`
        headers.Accept = 'application/json'
        headers['Content-Type'] = 'application/json'

        const response = await fetch(url, {
          method: 'DELETE',
          headers,
        })
        if (!response.ok) throw new Error(response.statusText)
      },
    },
  },
}
