import axios from 'axios'
import { Material } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/materials`

export default {
  getAll: async () => {
    const res = await axios.get<Material[]>(baseUrl)
    return res.data
  },
  getById: async (materialId: string) => {
    const res = await axios.get<Material>(`${baseUrl}/${materialId}`)
    return res.data
  },
}
