import axios from 'axios'
import {
  FaqCategoriesResponseDto,
  FaqCategoryDto,
  FaqCategorySortOrderDto,
} from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/faq-categories`

export default {
  post: async (faqCategory: FaqCategoryDto, token: string) => {
    const { data } = await axios.post<FaqCategoryDto>(baseUrl, faqCategory, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  get: async (token?: string) => {
    const { data } = await axios.get<FaqCategoriesResponseDto>(baseUrl, {
      headers: {
        Authorization: token ? `Bearer ${token}` : undefined,
      },
    })
    return data
  },
  put: async (faqCategories: FaqCategorySortOrderDto[], token: string) => {
    const { data } = await axios.put<FaqCategoryDto>(baseUrl, faqCategories, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  id: {
    get: async (token: string, id: string) => {
      const { data } = await axios.get<FaqCategoryDto>(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    put: async (id: string, faqCategory: FaqCategoryDto, token: string) => {
      const { data } = await axios.put<FaqCategoryDto>(
        `${baseUrl}/${id}`,
        faqCategory,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )
      return data
    },
    delete: async (id: string, token: string) => {
      await axios.delete<void>(`${baseUrl}/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
    },
  },
}
