<template>
  <transition name="fade">
    <div
      class="fixed bottom-5 left-0 right-0 z-[100] m-auto flex w-full flex-col gap-2 sm:w-[35rem]"
      v-if="message"
    >
      <RsToastMessage
        :message="message"
        @close-toast-banner="closeToastBanner"
      />
    </div>
  </transition>
</template>

<script lang="ts" setup>
import { storeToRefs } from 'pinia'
import { defineAsyncComponent } from 'vue'
import { useToast } from '../hooks/useToast'

const RsToastMessage = defineAsyncComponent(
  () => import('./RsToastMessage.vue'),
)

const toast = useToast()
const { message } = storeToRefs(toast)
const { timeoutId } = storeToRefs(toast)

const closeToastBanner = () => {
  message.value = null
  timeoutId.value = null
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
