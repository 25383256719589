import { createRouter, createWebHistory } from 'vue-router'
import { getAuth } from '@firebase/auth'
import { useLanguage } from '../hooks/useLanguage'
import { useUserAbility } from '../hooks/useUserAbility'
import { useSiteTitle } from '../hooks/useSiteTitle'

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'PageHomeB',
      component: () => import('../pages/PageHomeB.vue'),
      meta: {
        title: 'navigation.homePage',
      },
    },
    {
      path: '/offers/me',
      name: 'PageMyOffers',
      component: () => import('../pages/PageMyOffers.vue'),
      meta: {
        title: 'navigation.myOffers',
        requiresAuth: true,
      },
    },
    {
      path: '/offers-my',
      name: 'PageMyOffers',
      component: () => import('../pages/PageMyOffersB.vue'),
      meta: {
        title: 'navigation.myOffers',
        requiresAuth: true,
      },
    },
    {
      path: '/auctions/participated',
      name: 'PageParticipatedAuctions',
      component: () => import('../pages/PageParticipatedAuctions.vue'),
      meta: {
        title: 'navigation.participatedAuctions',
        requiresAuth: true,
      },
    },
    {
      path: '/offers/new/:workItem',
      name: 'PageOfferForm',
      component: () => import('../pages/PageOfferForm.vue'),
      meta: {
        title: 'navigation.createOffer',
        requiresAuth: true,
        caslAbility: 'create',
        caslSubject: 'Offer',
        caslRedirect: '/ask-for?role=OfferProvider',
      },
      beforeEnter: (to, from, next) => {
        if (to.params.workItem !== 'offer' && to.params.workItem !== 'item') {
          next(false)
          window.history.back()
        } else {
          next()
        }
      },
    },
    {
      path: '/auctions/new',
      name: 'PageAuctionNew',
      component: () => import('../pages/PageAuctionNew.vue'),
      meta: {
        title: 'navigation.createAuction',
        requiresAuth: true,
        caslAbility: 'create',
        caslSubject: 'Offer',
        caslRedirect: '/ask-for?role=OfferProvider',
      },
    },
    {
      path: '/offers/:id',
      name: 'PageOffer',
      component: () => import('../pages/PageOffer.vue'),
      meta: {
        title: 'navigation.offer',
      },
    },
    {
      path: '/auctions/:id',
      name: 'PageAuction',
      component: () => import('../pages/PageAuction.vue'),
      meta: {
        title: 'navigation.auction',
      },
    },
    {
      path: '/offers/:id/success',
      name: 'PageOfferNewSuccess',
      component: () => import('../pages/PageOfferNewSuccess.vue'),
    },
    {
      path: '/offers/:id/edit',
      name: 'PageOfferEdit',
      component: () => import('../pages/PageOfferEdit.vue'),
      meta: {
        title: 'navigation.editOffer',
        requiresAuth: true,
        caslAbility: 'create',
        caslSubject: 'Offer',
        caslRedirect: '/ask-for?role=OfferProvider',
      },
    },
    {
      path: '/auctions/:id/edit',
      name: 'PageAuctionEdit',
      component: () => import('../pages/PageOfferEdit.vue'),
      meta: {
        title: 'navigation.editAuction',
        requiresAuth: true,
        caslAbility: 'create',
        caslSubject: 'Offer',
        caslRedirect: '/ask-for?role=OfferProvider',
      },
    },
    {
      path: '/newsletter',
      name: 'PageNewsletter',
      component: () => import('../pages/PageNewsletter.vue'),
      meta: {
        title: 'navigation.newsletter',
      },
    },
    {
      path: '/newsletter-success',
      name: 'PageNewsletterSuccess',
      component: () => import('../pages/PageNewsletterSuccess.vue'),
      meta: {
        title: 'navigation.newsletter-success',
      },
    },
    {
      path: '/profile',
      name: 'PageProfile',
      component: () => import('../pages/PageProfile.vue'),
      meta: {
        title: 'navigation.profile',
        requiresAuth: true,
      },
    },
    {
      path: '/profile/me',
      name: 'PageProfileData',
      component: () => import('../pages/PageProfileData.vue'),
      meta: {
        title: 'navigation.profileData',
        requiresAuth: true,
      },
    },
    {
      path: '/login-sbb',
      name: 'PageLoginSbb',
      component: () => import('../pages/PageLoginSbb.vue'),
      beforeEnter: (to, from, next) => {
        const auth = getAuth()
        const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(
          (user) => {
            if (user) {
              next('/profile')
            } else {
              next()
            }
          },
        )
        unsubscribeOnAuthStateChanged()
      },
    },
    {
      path: '/login',
      name: 'PageLogin',
      component: () => import('../pages/PageLogin.vue'),
      meta: {
        title: 'navigation.login',
      },
      beforeEnter: (to, from, next) => {
        const auth = getAuth()
        const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(
          (user) => {
            if (user) {
              next('/profile')
            } else {
              next()
            }
          },
        )
        unsubscribeOnAuthStateChanged()
      },
    },
    {
      path: '/usermgmt',
      name: 'UserManagement',
      component: () => import('../pages/PageUserManagement.vue'),
      props: (route) => ({
        mode: route.query.mode,
        oobCode: route.query.oobCode,
        continueUrl: route.query.continueUrl,
      }),
    },
    {
      path: '/login/forgot-password',
      name: 'PageForgotPassword',
      component: () => import('../pages/PageForgotPassword.vue'),
      meta: {
        title: 'navigation.forgotPassword',
      },
      beforeEnter: (to, from, next) => {
        const auth = getAuth()
        const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(
          (user) => {
            if (user) {
              next('/profile')
            } else {
              next()
            }
          },
        )
        unsubscribeOnAuthStateChanged()
      },
    },
    {
      path: '/register',
      name: 'PageRegister',
      component: () => import('../pages/PageRegister.vue'),
      meta: {
        title: 'Register',
      },
    },
    {
      // todo in /users
      path: '/login/reset-password',
      name: 'PageResetPassword',
      component: () => import('../pages/PageResetPassword.vue'),
      meta: {
        title: 'navigation.resetPassword',
      },
    },
    {
      path: '/logout',
      name: 'PageLogout',
      redirect: () => {
        const auth = getAuth()
        auth.signOut()
        return '/'
      },
    },
    {
      path: '/theme-preview',
      name: 'PageThemePreview',
      component: () => import('../pages/PageThemePreview.vue'),
      meta: {
        title: 'navigation.theme',
      },
    },
    {
      path: '/settings',
      name: 'PageSettings',
      component: () => import('../pages/PageSettings.vue'),
      meta: {
        title: 'navigation.settings',
      },
    },
    {
      path: '/ask-for',
      name: 'PageAskForRole',
      component: () => import('../pages/PageAskForRole.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/already-asked',
      name: 'PageAlreadyAsked',
      component: () => import('../pages/PageAlreadyAsked.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/cms',
      name: 'PageCmsHome',
      component: () => import('../pages/PageCmsHome.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/offers-to-approve',
      name: 'PageCmsOffersToApprove',
      component: () => import('../pages/PageCmsOffersToApprove.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/offers-to-approve/:id',
      name: 'PageCmsOfferToApprove',
      component: () => import('../pages/PageCmsOfferToApprove.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/goods-groups',
      name: 'PageCmsGoodsGroups',
      component: () => import('../pages/PageCmsGoodsGroups.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/goods-groups/new',
      name: 'PageCmsGoodsGroupNew',
      component: () => import('../pages/PageCmsGoodsGroupNew.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/goods-groups/:id/edit',
      name: 'PageCmsGoodsGroupEdit',
      component: () => import('../pages/PageCmsGoodsGroupEdit.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/role-requests',
      name: 'PageCmsRoleRequests',
      component: () => import('../pages/PageCmsRoleRequests.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies',
      name: 'PageCmsCompanies',
      component: () => import('../pages/PageCmsCompanies.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies/:id/edit',
      name: 'PageCmsCompanyEdit',
      component: () => import('../pages/PageCmsCompanyEdit.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies/:id/incoterm/edit',
      name: 'CmsIncoterm',
      component: () => import('../pages/PageCmsIncoterm.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies/:id/edit/logo',
      name: 'PageCmsCompanyEditLogo',
      component: () => import('../pages/PageCmsCompanyEditLogo.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies/:id/edit/invalid-delivery-dates',
      name: 'PageCmsEditInvalidDeliveryDates',
      component: () => import('../pages/PageCmsEditInvalidDeliveryDates.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/companies/new',
      name: 'PageCmsCompanyNew',
      component: () => import('../pages/PageCmsCompanyNew.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/hero-section',
      name: 'PageCmsHeroSection',
      component: () => import('../pages/PageCmsHeroSection.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/hero-section/new',
      name: 'PageCmsHeroSectionNew',
      component: () => import('../pages/PageCmsHeroSectionNew.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/hero-section/:id/edit',
      name: 'PageCmsHeroSectionEdit',
      component: () => import('../pages/PageCmsHeroSectionEdit.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/promote-offers',
      name: 'PageCmsPromoteOffers',
      component: () => import('../pages/PageCmsPromoteOffers.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/promote-offers/new',
      name: 'PageCmsPromoteOffersNew',
      component: () => import('../pages/PageCmsPromoteOffersNew.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/news',
      name: 'PageCmsNews',
      component: () => import('../pages/PageCmsNews.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/news/new',
      name: 'PageCmsNewsNew',
      component: () => import('../pages/PageCmsNewsNew.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/news/:id/edit',
      name: 'PageCmsNewsEdit',
      component: () => import('../pages/PageCmsNewsEdit.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/offers',
      name: 'PageCmsOffers',
      component: () => import('../pages/PageCmsOffers.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/users',
      name: 'PageCmsUsers',
      component: () => import('../pages/PageCmsUsers.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/checkouts',
      name: 'PageCmsCheckouts',
      component: () => import('../pages/PageCmsCheckouts.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/kpi/statistics',
      name: 'PageCmsKpiStatistics',
      component: () => import('../pages/PageCmsKpiStatistics.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/locations',
      name: 'PageCmsLocations',
      component: () => import('../pages/PageCmsLocations.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/faq',
      name: 'PageCmsFaq',
      component: () => import('../pages/PageCmsFaq.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/faq/:id/edit',
      name: 'PageCmsEditFaq',
      component: () => import('../pages/PageCmsEditFaq.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/news',
      name: 'PageNewsFeed',
      component: () => import('../pages/PageNewsFeed.vue'),
    },
    {
      path: '/news/:id',
      name: 'PageNews',
      component: () => import('../pages/PageNews.vue'),
    },
    {
      path: '/verify-email',
      name: 'PageVerifyEmail',
      component: () => import('../pages/PageVerifyEmail.vue'),
    },
    {
      path: '/terms-of-service',
      name: 'PageTermsOfService',
      component: () => import('../pages/PageTermsOfService.vue'),
    },
    {
      path: '/imprint',
      name: 'PageImprint',
      component: () => import('../pages/PageImprint.vue'),
    },
    {
      path: '/third-party-licences',
      name: 'PageThirdPartyLicences',
      component: () => import('../pages/PageThirdPartyLicences.vue'),
    },
    {
      path: '/about-us',
      name: 'PageAboutUs',
      component: () => import('../pages/PageAboutUs.vue'),
    },
    {
      path: '/offers',
      name: 'PageOffers',
      component: () => import('../pages/PageOffers.vue'),
    },
    {
      path: '/credits',
      name: 'PageCredits',
      component: () => import('../pages/PageCredits.vue'),
    },
    {
      path: '/companies/:id/tos',
      name: 'PageCompaniesTos',
      component: () => import('../pages/PageCompanyTermsAndConditions.vue'),
    },
    {
      path: '/cms/terms-of-service',
      name: 'PageCmsTermsOfService',
      component: () => import('../pages/PageCmsTermsOfService.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/newsletter-sign-ups',
      name: 'PageCmsNewsletterSignUps',
      component: () => import('../pages/PageCmsNewsletterSignUps.vue'),
    },
    {
      path: '/cms/imprint',
      name: 'PageCmsImprint',
      component: () => import('../pages/PageCmsImprint.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/about-us',
      name: 'PageCmsAboutUs',
      component: () => import('../pages/PageCmsAboutUs.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/feature-flags',
      name: 'PageCmsFeatureFlags',
      component: () => import('../pages/PageCmsFeatureFlags.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=SystemAdmin',
      },
    },
    {
      path: '/shopping-cart',
      name: 'PageShoppingCart',
      component: () => import('../pages/PageShoppingCart.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/profile/customers',
      name: 'PageCustomers',
      component: () => import('../pages/PageMyCustomers.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/profile/checkouts',
      name: 'MyCheckouts',
      component: () => import('../pages/PageMyCheckouts.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:id',
      name: 'PageCustomer',
      component: () => import('../pages/PageCustomer.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/cms/customers',
      name: 'PageCmsEditCustomers',
      component: () => import('../pages/PageCmsEditCustomers.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/customers/new',
      name: 'PageCmsAddCustomer',
      component: () => import('../pages/PageCmsAddCustomer.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/customers/:id/debitors',
      name: 'PageEditCustomerDebitors',
      component: () => import('../pages/PageEditCustomerDebitors.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/customers/:id/debitors/:debitorNumber',
      name: 'PageEditDebitor',
      component: () => import('../pages/PageEditDebitor.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/checkout',
      name: 'PageCheckoutNew',
      component: () => import('../pages/PageCheckoutNew.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/checkouts/:id',
      name: 'PageCheckout',
      component: () => import('../pages/PageCheckout.vue'),
      props: true,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/checkouts/:id/edit/order-confirmation',
      name: 'PageEditOrderConfirmation',
      component: () => import('../pages/PageEditOrderConfirmation.vue'),
      props: true,
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/feedback',
      name: 'PageFeedback',
      component: () => import('../pages/PageFeedback.vue'),
    },
    {
      path: '/feedback-success',
      name: 'PageFeedbackSuccess',
      component: () => import('../pages/PageFeedbackSuccess.vue'),
    },
    {
      path: '/profile/search-subscriptions',
      name: 'PageMySearchSubscriptions',
      component: () => import('../pages/PageMySearchSubscriptions.vue'),
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: '/reset-password',
      name: 'PageResetPassword',
      component: () => import('../pages/PageResetPassword.vue'),
    },
    {
      path: '/change-password',
      name: 'PageChangePassword',
      component: () => import('../pages/PageChangePassword.vue'),
    },
    {
      path: '/cms/companies/:id/theme',
      name: 'PageCmsThemeManage',
      component: () => import('../pages/PageCmsThemeManage.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/themes',
      name: 'PageCmsTheme',
      component: () => import('../pages/PageCmsThemes.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/cms/themes/:id/edit',
      name: 'PageCmsThemeManage2',
      component: () => import('../pages/PageCmsThemeManage.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/clock',
      name: 'PageClock',
      component: () => import('../pages/PageClock.vue'),
    },
    {
      path: '/offers/new/preview',
      name: 'OfferPreview',
      component: () => import('../components/OfferPreview.vue'),
      meta: {
        title: 'navigation.createOffer',
        requiresAuth: true,
        caslAbility: 'create',
        caslSubject: 'Offer',
        caslRedirect: '/ask-for?role=OfferProvider',
      },
    },
    {
      path: '/profile/me/bookmarks',
      name: 'BookMarks',
      component: () => import('../pages/PageBookmarks.vue'),
      meta: {
        title: 'navigation.bookmarks',
        requiresAuth: true,
      },
    },
    {
      path: '/profile/me/bookmarks/:id',
      name: 'BookMarksDetailPage',
      component: () => import('../pages/PageBookmarkDetail.vue'),
      meta: {
        title: 'navigation.bookmark',
        requiresAuth: true,
      },
    },
    {
      path: '/cms/levels',
      name: 'PageCmsLevels',
      component: () => import('../pages/PageCmsLevels.vue'),
      meta: {
        requiresAuth: true,
        caslAbility: 'edit',
        caslSubject: 'SiteContent',
        caslRedirect: '/ask-for?role=ContentManager',
      },
    },
    {
      path: '/faq',
      name: 'Faq',
      component: () => import('../pages/PageFaq.vue'),
      meta: {
        requiresAuth: false,
      },
    },
    {
      path: '/:catchAll(.*)',
      name: 'PageNotFound',
      component: () => import('../pages/PageNotFound.vue'),
      meta: {
        title: 'navigation.notFound',
      },
    },
  ],
})

router.beforeEach((to, from, next) => {
  const auth = getAuth()
  const language = useLanguage()
  /*  use getAbility instead of useAbility since injection from
      useAbility not working outside component or setup function  */

  /* ---- PAGE TITLE ---- */
  const defaultTitle = useSiteTitle()
  if (to.meta.title) {
    document.title = `${language.i18nInstance.global.t(
      to.meta.title as string,
    )} - ${defaultTitle.value}`
  } else {
    document.title = (to.meta.title as string) || defaultTitle.value
  }

  /* ---- AUTHENTICATION AND AUTHORIZATION ---- */
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const unsubscribeOnAuthStateChanged = auth.onAuthStateChanged(
      async (user) => {
        const { cannot } = await useUserAbility()

        if (user) {
          if (!user.emailVerified) {
            next({
              path: '/verify-email',
              query: { redirect: to.fullPath },
            })
          }
          if (
            cannot(
              to.meta.caslAbility as string,
              to.meta.caslSubject as string,
            ) &&
            to.meta.caslRedirect
          ) {
            next(to.meta.caslRedirect as string)
          }
          next()
        } else {
          next({
            path: '/login',
            query: { redirect: to.fullPath },
          })
        }
      },
    )
    unsubscribeOnAuthStateChanged()
  } else {
    next()
  }
})

router.afterEach((to) => {
  const { currentLanguage } = useLanguage()
  const auth = getAuth()
  const signInProvider = auth.currentUser?.providerData[0].providerId
  window.digitalDataLayer = window.digitalDataLayer || []
  window.digitalDataLayer?.push({
    pageInstanceID: import.meta.env.VITE_ANALYTICS_PAGE_INSTANCE_ID,
    page: {
      pageInfo: {
        pageID: to.name || to.path,
        pageName: to.name || to.path,
        destinationURL: document.location.href,
        destinationURI: to.path,
        referringURL: document.referrer,
        sysEnv: import.meta.env.VITE_ANALYTICS_SYS_ENV,
        language: currentLanguage.value,
      },
      category: {
        primaryCategory: import.meta.env.VITE_ANALYTICS_PRIMARY_CATEGORY,
      },
    },
    user: [
      {
        profile: [
          {
            profileInfo: {
              loginType: signInProvider || '',
              loginStatus: auth.currentUser ? '1' : '0',
              language: currentLanguage.value,
            },
          },
        ],
      },
    ],
  })

  const dataLayerEvent = {
    event: {
      eventInfo: {
        eventName: 'page data layer ready',
      },
    },
  }

  window?.digitalDataLayer?.push(dataLayerEvent)
})

export default router

declare global {
  interface Window {
    digitalDataLayer: any[] | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  }
}
