import axios from 'axios'
import { LogFilter, LogsResponseDto } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/logs`

export default {
  getAll: async (
    token: string,
    options?: LogFilter,
  ): Promise<LogsResponseDto> => {
    const { data } = await axios.get(baseUrl, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      params: options,
    })
    return data
  },
}
