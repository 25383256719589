import axios from 'axios'
import { EditableInfo, EditableInfoPut } from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/editable-text`

export default {
  get: async () => {
    const { data } = await axios.get<EditableInfo[]>(baseUrl)
    return data
  },
  name: {
    get: async (name: string) => {
      const url = `${baseUrl}/${name}`
      const { data } = await axios.get<EditableInfo>(url)
      return data
    },

    put: async (name: string, body: EditableInfoPut, token: string) => {
      const url = `${baseUrl}/${name}`
      const { data } = await axios.put<EditableInfo>(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
  },
}
