import axios from 'axios'
import {
  SearchSubscription,
  SearchSubscriptionCreateDto,
  SearchSubscriptionPatchDto,
  SearchSubscriptionResponseDto,
} from '../schemas'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'

export default {
  post: async (body: SearchSubscriptionCreateDto, token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/search-subscriptions`
    const { data } = await axios.post<SearchSubscription>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  get: async (
    params: {
      skip?: number
      limit?: number
    } = {
      skip: 0,
      limit: 0,
    },
    token: string,
  ) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/search-subscriptions`
    const urlWithParams = new URL(url)

    urlWithParams.search = objectToSearchParams(params).toString()

    const { data } = await axios.get<SearchSubscriptionResponseDto>(
      urlWithParams.toString(),
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    )

    return data
  },
  id: {
    delete: async (id: string, token: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/search-subscriptions/${id}`

      const { data } = await axios.delete<SearchSubscription>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return data
    },
    patch: async (
      id: string,
      body: SearchSubscriptionPatchDto,
      token: string,
    ) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/search-subscriptions/${id}`

      const { data } = await axios.patch<SearchSubscription>(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })

      return data
    },
  },
}
