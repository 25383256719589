export async function setupAnalytics() {
  if (import.meta.env.VITE_ANALYTICS_IS_COLLECTION_ENABLED !== 'true') return

  const analyticsScript = document.createElement('script')
  analyticsScript.setAttribute('type', 'text/javascript')
  analyticsScript.setAttribute('defer', '')
  analyticsScript.setAttribute('src', import.meta.env.VITE_ANALYTICS_SCRIPT_URL)

  const satellite = document.createElement('script')
  satellite.setAttribute('type', 'text/javascript')
  satellite.setAttribute('defer', '')
  const code = '_satellite.pageBottom();'
  satellite.appendChild(document.createTextNode(code))

  analyticsScript.addEventListener('load', () => {
    document.body.appendChild(satellite)
  })

  document.head.appendChild(analyticsScript)
}
