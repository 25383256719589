import { objectToSearchParams } from '../../../libs/objectToSearchParams'
import {
  News,
  CreateNewsDto,
  NewsFilter,
  NewsPaginationResponseDto,
  UpdateNewsDto,
} from '../schemas'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/news`

export default {
  post: async (payload: CreateNewsDto, token: string): Promise<News> => {
    const url = `${baseUrl}`
    const headers: HeadersInit = {}
    headers.Authorization = `Bearer ${token}`
    headers.Accept = 'application/json'
    headers['Content-Type'] = 'application/json'

    const body = JSON.stringify(payload)

    const response = await fetch(url, {
      method: 'POST',
      headers,
      body,
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: News = await response.json()
    return data
  },
  get: async (
    params: NewsFilter,
    token?: string,
  ): Promise<NewsPaginationResponseDto> => {
    const urlParams = objectToSearchParams(params).toString()
    const url = `${baseUrl}?${urlParams}`

    const headers: HeadersInit = {}
    if (token) {
      headers.Authorization = `Bearer ${token}`
    }

    const response = await fetch(url, { headers })
    if (!response.ok) throw new Error(response.statusText)

    const data: NewsPaginationResponseDto = await response.json()
    return data
  },
  id: {
    get: async (id: string, token?: string): Promise<News> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }

      const response = await fetch(url, { headers })
      if (!response.ok) throw new Error(response.statusText)

      const data: News = await response.json()
      return data
    },
    patch: async (
      id: string,
      payload: UpdateNewsDto,
      token: string,
    ): Promise<News> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      headers.Authorization = `Bearer ${token}`
      headers.Accept = 'application/json'
      headers['Content-Type'] = 'application/json'

      const body = JSON.stringify(payload)

      const response = await fetch(url, {
        method: 'PATCH',
        headers,
        body,
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: News = await response.json()
      return data
    },
    delete: async (id: string, token: string): Promise<void> => {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      headers.Authorization = `Bearer ${token}`
      headers.Accept = 'application/json'
      headers['Content-Type'] = 'application/json'

      const response = await fetch(url, {
        method: 'DELETE',
        headers,
      })
      if (!response.ok) throw new Error(response.statusText)
    },
    thumbnail: {
      put: async (id: string, image: File, token: string): Promise<string> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`

        const body = new FormData()
        body.append('file', image)

        const response = await fetch(url, {
          method: 'PUT',
          headers,
          body,
        })

        if (!response.ok) throw new Error(response.statusText)

        const data: string = await response.text()
        return data
      },
      get: async (id: string, token?: string): Promise<string> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        if (token) {
          headers.Authorization = `Bearer ${token}`
        }

        const response = await fetch(url, { headers })
        if (!response.ok) throw new Error(response.statusText)

        const data: string = await response.text()
        return data
      },
      delete: async (id: string, token: string): Promise<void> => {
        const url = `${baseUrl}/${id}/thumbnail`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`
        headers.Accept = 'application/json'
        headers['Content-Type'] = 'application/json'

        const response = await fetch(url, {
          method: 'DELETE',
          headers,
        })
        if (!response.ok) throw new Error(response.statusText)
      },
    },
  },
}
