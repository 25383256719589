import offers from './offers'
import auctions from './auctions'
import promotions from './promotions'
import users from './users'
import goodsGroups from './goodsGroups'
import roleRequests from './roleRequests'
import companies from './companies'
import news from './news'
import legalInfo from './legal-Info'
import logs from './logs-events'
import newsletterSignUps from './newsletter-sign-ups'
import heroSection from './hero-section'
import featureFlags from './featureFlags'
import customers from './customers'
import checkouts from './checkouts'
import userInformations from './user-informations'
import feedbacks from './feedbacks'
import searchSubscriptions from './search-subscriptions'
import editableText from './editableText'
import themes from './themes'
import bookmarks from './bookmarks'
import locations from './locations'
import levels from './levels'
import material from './material'
import comments from './comments'
import faqCategories from './faqCategories'

export default {
  offers,
  auctions,
  promotions,
  users,
  goodsGroups,
  roleRequests,
  companies,
  news,
  legalInfo,
  logs,
  newsletterSignUps,
  heroSection,
  featureFlags,
  customers,
  checkouts,
  userInformations,
  feedbacks,
  searchSubscriptions,
  editableText,
  themes,
  bookmarks,
  locations,
  levels,
  material,
  comments,
  faqCategories,
}
