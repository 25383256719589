import axios from 'axios'
import { getAuth } from 'firebase/auth'
import {
  Checkout,
  CompanyResponseDto,
  CustomersResponseDto,
  OfferResponseDto,
  RoleRequestResponseDto,
  User,
  UserEmailVerifiedPutDto,
  UserQuery,
} from '../schemas'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/users`

export default {
  get: async (query: UserQuery, token: string) => {
    const url = `${baseUrl}`
    const urlWithParams = new URL(url)
    urlWithParams.search = objectToSearchParams(query).toString()

    const { data } = await axios.get<User[]>(urlWithParams.toString(), {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  id: {
    async get(id: string, token?: string): Promise<User> {
      const url = `${baseUrl}/${id}`
      const headers: HeadersInit = {}
      if (token) {
        headers.Authorization = `Bearer ${token}`
      }

      const response = await fetch(url, { headers })
      if (!response.ok) throw new Error(response.statusText)

      const data: User = await response.json()
      return data
    },
    emailVerified: {
      put: async (id: string, body: UserEmailVerifiedPutDto, token: string) => {
        const url = `${baseUrl}/${id}/emailVerified`

        const { data } = await axios.put<User>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
    checkouts: {
      get: async (id: string, token: string): Promise<Checkout[]> => {
        const url = `${baseUrl}/${id}/checkouts`
        const { data } = await axios.get<Checkout[]>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
    customers: {
      get: async (id: string, token: string): Promise<CustomersResponseDto> => {
        const url = `${baseUrl}/${id}/customers`
        const { data } = await axios.get<CustomersResponseDto>(url, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
    company: {
      get: async (id: string, token: string): Promise<CompanyResponseDto> => {
        const url = `${baseUrl}/${id}/company`
        const headers: HeadersInit = {}
        if (token) {
          headers.Authorization = `Bearer ${token}`
        }
        const response = await fetch(url, { headers })
        if (!response.ok) throw new Error(response.statusText)

        const data: CompanyResponseDto = await response.json()
        return data
      },
    },
    async user(id: string): Promise<User> {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/users/${id}`
      const res = await fetch(url)
      if (!res.ok) throw new Error()

      const data = await res.json()
      return data
    },
    async roleRequests(
      id: string,
      token: string,
    ): Promise<RoleRequestResponseDto[]> {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/users/${id}/role-requests`
      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!res.ok) throw new Error()

      const data = await res.json()
      return data
    },
  },
  password: {
    async resetPassword(email: string) {
      const url = `${baseUrl}/reset-password`
      const body = { email }
      try {
        await axios.post(url, body, {})
      } catch (error) {
        console.error(error)
      }
    },
  },
  me: {
    async offers(): Promise<OfferResponseDto[]> {
      const { currentUser } = getAuth()
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/users/${currentUser?.uid}/offers`

      console.log('get offers from: ', url)
      const response = await fetch(url.toString())
      if (!response.ok) throw new Error(response.statusText)

      const data: OfferResponseDto[] = await response.json()
      return data
    },
  },
}
