export enum ToastType {
  SUCCESS,
  ERROR,
  INFO,
  WARNING,
}

export interface Message {
  message: string
  type: ToastType
}

import { defineStore } from 'pinia'

export const useToast = defineStore('toast', {
  state: () => {
    return {
      message: null as Message | null,
      timeoutId: null as ReturnType<typeof setTimeout> | number | null,
    }
  },
  actions: {
    showMessage(message: string, type: ToastType) {
      if (this.timeoutId != -1) {
        clearTimeout(this.timeoutId as ReturnType<typeof setTimeout>)
      }
      this.message = { message, type }
      this.timeoutId = setTimeout(() => {
        this.message = null
        this.timeoutId = null
      }, 5000)
    },
  },
})
