import { ref } from 'vue'
import api from '../api'
import { useCssVar, useFavicon } from '@vueuse/core'

const el = ref(null)
const hue = ref('--brand-hue')
const saturation = ref('--brand-saturation')
const lightness = ref('--brand-lightness')
const logoUrl = ref('')
const homePageTitle = ref({ de: '', fr: '', en: '', it: '' })
const siteTitle = ref({ de: '', fr: '', en: '', it: '' })
const impressum = ref({ de: '', fr: '', en: '', it: '' })
const hostUrl = ref('')
const companies = ref<string[]>([])
const isCookieBannerFeatureFlagActive = ref(true)

const hueVal = useCssVar(hue, el)
const saturationVal = useCssVar(saturation, el)
const lightnessVal = useCssVar(lightness, el)

const host = window.location.host

const setTheme = async () => {
  try {
    const response = api.resale.themes.get({ host: host })
    const results = (await response).results

    results.forEach((e) => {
      hostUrl.value = e.host
      hueVal.value = e.brandHue
      saturationVal.value = e.brandSaturation + '%'
      lightnessVal.value = e.brandLightness + '%'
      homePageTitle.value = e.homePageTitle
      siteTitle.value = e.siteTitle
      impressum.value = e.impressum
      companies.value = e.companies
      e.featureFlags.find((featureflag) => {
        if (featureflag.name == 'cookie-banner') {
          return (isCookieBannerFeatureFlagActive.value = featureflag.isActive)
        }
      })
      api.resale.themes.id.favicon.get(e.id).then((favicon) => {
        if (favicon !== '') useFavicon(favicon)
      })
      api.resale.themes.id.logo.get(e.id).then((logo) => {
        if (logo !== '') logoUrl.value = logo
      })
    })
  } catch (e) {
    console.log(e)
  }
}

export function useCompanyTheme() {
  return {
    setTheme: setTheme,
    getLogo: logoUrl,
    getHomePageTitle: homePageTitle,
    getSiteTitle: siteTitle,
    getImpressum: impressum,
    getCompanies: companies,
    getCookieBannerFeatureFlag: isCookieBannerFeatureFlagActive,
    hostUrl,
  }
}
