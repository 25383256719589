import axios from 'axios'
import {
  GoodsGroupCreateDto,
  GoodsGroupResponseDto,
  GoodsGroupUpdateDto,
  OfferPaginationResponseDto,
} from '../schemas'

export default {
  get: async (): Promise<GoodsGroupResponseDto[]> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/goods-groups`
    console.log('get goods groups from: ', url)
    const response = await fetch(url)
    if (!response.ok) throw new Error(response.statusText)

    const data: GoodsGroupResponseDto[] = await response.json()
    return data
  },
  post: async (
    body: GoodsGroupCreateDto,
    token: string,
  ): Promise<GoodsGroupResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/goods-groups`
    console.log('create goods group on: ', url)

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: GoodsGroupResponseDto = await response.json()
    return data
  },
  id: {
    get: async (id: string): Promise<GoodsGroupResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/goods-groups/${id}`

      console.log('get goods group from: ', url)
      const response = await fetch(url)
      if (!response.ok) throw new Error(response.statusText)

      const data: GoodsGroupResponseDto = await response.json()
      return data
    },
    patch: async (
      id: string,
      body: GoodsGroupUpdateDto,
      token: string,
    ): Promise<GoodsGroupResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/goods-groups/${id}`

      console.log('patch goods group on: ', url)
      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: GoodsGroupResponseDto = await response.json()
      return data
    },
    delete: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/goods-groups/${id}`
      const { data } = await axios.delete<GoodsGroupResponseDto>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    offers: async (
      id: string,
      token?: string,
    ): Promise<OfferPaginationResponseDto> => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/goods-groups/${id}/offers`

      console.log('get offers from: ', url)
      const response = await fetch(
        url,
        token
          ? {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          : {},
      )
      if (!response.ok) throw new Error(response.statusText)

      const data: OfferPaginationResponseDto = await response.json()
      return data
    },
  },
}
