import axios from 'axios'
import {
  AuctionCreateDto,
  AuctionResponseDto,
  AuctionUpdateDto,
} from '../schemas'

export default {
  post: async (
    body: AuctionCreateDto,
    token: string,
  ): Promise<AuctionResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/auctions`
    console.log(
      'post auction to: ',
      `${import.meta.env.VITE_RESALE_API_HOST}/auctions`,
    )
    const form = new FormData()
    form.append('title', JSON.stringify(body.title))
    form.append('description', JSON.stringify(body.description))
    form.append('startBidPrice', body.startBidPrice.toString())
    form.append('goodsGroup', body.goodsGroupId)
    form.append('currency', body.currency.toString())
    form.append('minimumBidIncrement', body.minimumBidIncrement.toString())

    if (body.startDate && body.startDate.toString() !== 'Invalid Date') {
      form.append('startDate', body.startDate.toISOString())
    }
    if (body.endDate && body.endDate.toString() !== 'Invalid Date') {
      form.append('endDate', body.endDate.toISOString())
    }
    if (body.publishDate && body.publishDate.toString() !== 'Invalid Date') {
      form.append('publishDate', body.publishDate.toISOString())
    }
    form.append('price', body.price?.toString() || '')

    body.images.forEach((file) => {
      console.log(file)
      form.append('images', file)
    })
    if (body.documents.length > 0) {
      body.documents.forEach((file) => {
        console.log(file)
        form.append('documents', file)
      })
    }

    form.append('thumbnailIndex', JSON.stringify(body.thumbnailIndex))

    form.append('contact', JSON.stringify(body.contact))

    form.append('links', JSON.stringify(body.links))

    form.append('quantity', body.quantity?.toString() || '')
    if (body.quantityUnit) {
      form.append('quantityUnit', body.quantityUnit?.toString())
    }
    form.append('zipCode', body.zipCode?.toString() || '')
    form.append('city', body.city?.toString() || '')
    if (body.condition) {
      form.append('condition', body.condition?.toString())
    }
    if (
      body.availableFrom &&
      body.availableFrom.toString() !== 'Invalid Date'
    ) {
      form.append('availableFrom', body.availableFrom?.toISOString())
    }
    if (
      body.availableTill &&
      body.availableTill.toString() !== 'Invalid Date'
    ) {
      form.append('availableTill', body.availableTill?.toISOString())
    }
    form.append('manufacturer', body.manufacturer?.toString() || '')
    form.append('constructionYear', body.constructionYear?.toString() || '')
    form.append('materialIds', JSON.stringify(body.materialIds))
    form.append('length', body.length?.toString() || '')
    if (body.lengthUnit) {
      form.append('lengthUnit', body.lengthUnit?.toString())
    }
    form.append('width', body.width?.toString() || '')
    if (body.widthUnit) {
      form.append('widthUnit', body.widthUnit?.toString())
    }
    form.append('height', body.height?.toString() || '')
    if (body.heightUnit) {
      form.append('heightUnit', body.heightUnit?.toString())
    }
    form.append('weight', body.weight?.toString() || '')
    if (body.weightUnit) {
      form.append('weightUnit', body.weightUnit?.toString())
    }
    form.append('visibility', JSON.stringify(body.visibility))
    form.append('contactPerson', JSON.stringify(body.contactPerson))

    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      body: form,
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: AuctionResponseDto = await response.json()
    return data
  },
  participated: async (token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/auctions/participated`
    console.log('get auctions from: ', url)
    const response = await fetch(
      url,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        : {},
    )
    if (!response.ok) throw new Error(response.statusText)

    const data = await response.json()

    return { offers: data, count: data.length }
  },
  id: {
    bid: async (id: string, token: string, surcharge: number) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/auctions/${id}/bid`
      console.log('bid for auction: ', url)
      const response = await fetch(url, {
        method: 'POST',
        body: JSON.stringify({ surcharge }),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) {
        const res = await response.json()
        throw new Error(res.message)
      }

      return response
    },
    patch: async (id: string, token: string, body: AuctionUpdateDto) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/auctions/${id}`
      console.log('patch auction to: ', url)
      const today = new Date()
      const hasAuctionStartedAlready =
        body.startDate && body.startDate.toISOString() <= today.toISOString()

      const form = new FormData()
      if (body.title) {
        form.append('title', JSON.stringify(body.title))
      }
      if (body.description) {
        form.append('description', JSON.stringify(body.description))
      }
      if (body.startBidPrice && !hasAuctionStartedAlready) {
        form.append('startBidPrice', body.startBidPrice.toString())
      }
      if (body.goodsGroupId) {
        form.append('goodsGroup', body.goodsGroupId)
      }
      if (body.currency && !hasAuctionStartedAlready) {
        form.append('currency', body.currency.toString())
      }
      if (
        body.startDate &&
        body.startDate.toString() !== 'Invalid Date' &&
        !hasAuctionStartedAlready
      ) {
        form.append('startDate', body.startDate.toISOString())
      }
      if (
        body.endDate &&
        body.endDate.toString() !== 'Invalid Date' &&
        !hasAuctionStartedAlready
      ) {
        form.append('endDate', body.endDate.toISOString())
      }
      if (
        body.publishDate &&
        body.publishDate.toString() !== 'Invalid Date' &&
        !hasAuctionStartedAlready
      ) {
        form.append('publishDate', body.publishDate.toISOString())
      }
      if (!hasAuctionStartedAlready) {
        form.append('price', body.price?.toString() || '')
      }

      if (body.images && body.images.length > 0) {
        body.images.forEach((file) => {
          console.log(file)
          form.append('images', file)
        })
      }
      if (body.documents && body.documents.length > 0) {
        body.documents.forEach((file) => {
          console.log(file)
          form.append('documents', file)
        })
      }

      form.append('contact', JSON.stringify(body.contact))
      form.append('minimumBidIncrement', body.minimumBidIncrement.toString())

      form.append('links', JSON.stringify(body.links))

      form.append('thumbnailIndex', JSON.stringify(body.thumbnailIndex))

      form.append('quantity', body.quantity?.toString() || '')
      if (body.quantityUnit) {
        form.append('quantityUnit', body.quantityUnit?.toString())
      }
      form.append('zipCode', body.zipCode?.toString() || '')
      form.append('city', body.city?.toString() || '')
      if (body.condition) {
        form.append('condition', body.condition?.toString())
      }
      if (
        body.availableFrom &&
        body.availableFrom.toString() !== 'Invalid Date'
      ) {
        form.append('availableFrom', body.availableFrom?.toISOString())
      }
      if (
        body.availableTill &&
        body.availableTill.toString() !== 'Invalid Date'
      ) {
        form.append('availableTill', body.availableTill?.toISOString())
      }
      form.append('manufacturer', body.manufacturer?.toString() || '')
      form.append('constructionYear', body.constructionYear?.toString() || '')
      form.append('materialIds', JSON.stringify(body.materialIds))
      form.append('length', body.length?.toString() || '')
      if (body.lengthUnit) {
        form.append('lengthUnit', body.lengthUnit?.toString())
      }
      form.append('width', body.width?.toString() || '')
      if (body.widthUnit) {
        form.append('widthUnit', body.widthUnit?.toString())
      }
      form.append('height', body.height?.toString() || '')
      if (body.heightUnit) {
        form.append('heightUnit', body.heightUnit?.toString())
      }
      form.append('weight', body.weight?.toString() || '')
      if (body.weightUnit) {
        form.append('weightUnit', body.weightUnit?.toString())
      }
      form.append('visibility', JSON.stringify(body.visibility))
      form.append('contactPerson', JSON.stringify(body.contactPerson))

      const response = await fetch(url, {
        method: 'PATCH',
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: form,
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: AuctionResponseDto = await response.json()
      return data
    },
    endDate: async (id: string, token: string) => {
      const response = await axios.get(
        `${import.meta.env.VITE_RESALE_API_HOST}/auctions/${id}/end-date`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      )

      return response.data
    },
  },
}
