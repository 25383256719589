import axios from 'axios'
import { Level, LevelFilter, LevelsResponseDto } from '../schemas'
import { objectToSearchParams } from '../../../libs/objectToSearchParams'

const baseUrl = `${import.meta.env.VITE_RESALE_API_HOST}/levels`

export default {
  get: async (params: LevelFilter, token?: string) => {
    const url = `${baseUrl}`
    const urlWithParams = new URL(url)
    urlWithParams.search = objectToSearchParams(params).toString()
    const { data } = await axios.get<LevelsResponseDto>(
      urlWithParams.toString(),
      {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      },
    )
    return data
  },
  userId: {
    get: async (userId: string, token?: string) => {
      const url = `${baseUrl}/${userId}`
      const { data } = await axios.get<Level>(url, {
        headers: {
          Authorization: token ? `Bearer ${token}` : undefined,
        },
      })
      return data
    },
    isPublic: {
      put: async (
        userId: string,
        body: { isPublic: boolean },
        token: string,
      ) => {
        const url = `${baseUrl}/${userId}/isPublic`
        const { data } = await axios.put<boolean>(url, body, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        return data
      },
    },
  },
}
