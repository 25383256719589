import { Ability, AbilityBuilder } from '@casl/ability'
import { getAuth } from 'firebase/auth'
import { Role } from '../api/resale/schemas'

const ability = new Ability()

export function useUserAbility() {
  const auth = getAuth()

  auth.onAuthStateChanged(async (authUser) => {
    const { can, cannot, rules } = new AbilityBuilder(Ability)

    if (authUser && authUser.emailVerified) {
      await new Promise((resolve) => {
        authUser?.getIdTokenResult(true).then((tokenResult) => {
          const roles: Role[] = tokenResult?.claims.roles as Role[]

          if (roles?.includes(Role.CONTENT_MANAGER)) {
            can('edit', 'SiteContent')
          }
          if (roles?.includes(Role.AUCTION_BIDDER)) {
            can('bid', ['Auction'])
          }
          if (roles?.includes(Role.OFFER_PROVIDER)) {
            can('create', ['Offer', 'Auction'])
          }
          can('edit', ['Offer', 'Auction'], {
            creator: { $eq: authUser.uid },
          })
          cannot(
            'edit',
            'Auction',
            [
              'price',
              'startBidPrice',
              'startDate',
              'endDate',
              'publishDate',
              'currency',
            ],
            {
              startDate: {
                $lte: new Date().toISOString(),
              },
            },
          )

          if (roles?.includes(Role.CONTENT_MANAGER)) {
            can('manage', ['Auction', 'Offer'])
          }

          if (roles?.includes(Role.SYSTEM_ADMIN)) {
            can('manage', 'all')
          }

          ability.update(rules)
          resolve(null)
        })
      })
    } else {
      cannot('edit', 'SiteContent')
      cannot('create', 'Offer')
      ability.update(rules)
    }
  })

  return ability
}

export function setupUserAbility() {
  const auth = getAuth()

  auth.onAuthStateChanged((authUser) => {
    const { can, cannot, rules } = new AbilityBuilder(Ability)
    if (authUser) {
      authUser?.getIdTokenResult().then((tokenResult) => {
        const roles: Role[] = tokenResult?.claims.roles as Role[]

        if (roles?.includes(Role.CONTENT_MANAGER)) {
          can('edit', 'SiteContent')
        }
        if (roles?.includes(Role.AUCTION_BIDDER)) {
          can('bid', ['Auction'])
        }
        if (roles?.includes(Role.OFFER_PROVIDER)) {
          can('create', ['Offer', 'Auction'])
        }
        can('edit', ['Offer', 'Auction'], {
          creator: { $eq: authUser.uid },
        })
        cannot(
          'edit',
          'Auction',
          [
            'price',
            'startBidPrice',
            'startDate',
            'endDate',
            'publishDate',
            'currency',
          ],
          {
            startDate: {
              $lte: new Date().toISOString(),
            },
          },
        )

        if (roles?.includes(Role.CONTENT_MANAGER)) {
          can('manage', ['Auction', 'Offer'])
        }

        if (roles?.includes(Role.SYSTEM_ADMIN)) {
          can('manage', 'all')
        }

        ability.update(rules)
      })
    } else {
      cannot('edit', 'SiteContent')
      cannot('create', 'Offer')
      ability.update(rules)
    }
  })

  return ability
}
