import { createApp } from 'vue'
import { createPinia } from 'pinia'
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate'
import App from './App.vue'
import router from './router'
import './index.css'
import { setupFirebase } from './bootstrap/firebase'
import { useLanguage } from './hooks/useLanguage'
import { abilitiesPlugin } from '@casl/vue'
import { setupUserAbility } from './hooks/useUserAbility'
import { Skeletor } from 'vue-skeletor'
import { useTheme } from './hooks/useTheme'
import { useCompanyTheme } from './hooks/useCompanyTheme'
import { setupCookieConstent } from './bootstrap/cookieConstent'
import { setupAnalytics } from './bootstrap/analytics'

console.log('Resale API Host:', import.meta.env.VITE_RESALE_API_HOST)

async function bootstrap() {
  await setupFirebase({
    apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
    authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
    projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
    storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
    appId: import.meta.env.VITE_FIREBASE_APP_ID,
    measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
  })
  const { i18nInstance, setLanguage, getApplicationLanguage } = useLanguage()
  const { setTheme } = useCompanyTheme()
  await setLanguage(getApplicationLanguage())
  useTheme()
  await setTheme()
  setupCookieConstent()
  setupAnalytics()
  const app = createApp(App)
  app.use(i18nInstance)
  const pinia = createPinia()
  pinia.use(piniaPluginPersistedstate)
  app.use(pinia)
  const ability = setupUserAbility()
  app.use(abilitiesPlugin, ability, { useGlobalProperties: true })
  app.use(router)
  app.component('Skeletor', Skeletor) // eslint-disable-line
  app.mount('#app')
}

await bootstrap()
