import axios from 'axios'
import {
  CompanyCreateDto,
  CompanyResponseDto,
  CompanyUpdateDto,
} from '../schemas'

export default {
  get: async (): Promise<CompanyResponseDto[]> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/companies`

    const response = await fetch(url)
    if (!response.ok) throw new Error(response.statusText)

    const data: CompanyResponseDto[] = await response.json()
    return data
  },
  post: async (
    body: CompanyCreateDto,
    token: string,
  ): Promise<CompanyResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/companies`

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: CompanyResponseDto = await response.json()
    return data
  },
  id: {
    get: async (
      id: string,
      options: {
        cache?: boolean
      } = {
        cache: false,
      },
    ) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/companies/${id}`

      const headers: { [key: string]: string } = {}
      if (options.cache) {
        headers['cache-control'] = 'max-age=600, must-revalidate'
      }

      const { data } = await axios.get<CompanyResponseDto>(url, {
        headers: headers,
      })
      return data
    },
    patch: async (
      id: string,
      body: CompanyUpdateDto,
      token: string,
    ): Promise<CompanyResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/companies/${id}`

      const response = await fetch(url, {
        method: 'PATCH',
        body: JSON.stringify(body),
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: CompanyResponseDto = await response.json()

      return data
    },
    delete: async (id: string, token: string): Promise<void> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/companies/${id}`

      const response = await fetch(url, {
        method: 'DELETE',
        headers: {
          Authorization: `Bearer ${token}`,
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      return
    },
    logo: {
      get: async (id: string, token?: string): Promise<string> => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/companies/${id}/logo`
        const headers: HeadersInit = {}
        if (token) {
          headers.Authorization = `Bearer ${token}`
        }
        const response = await fetch(url, {
          method: 'GET',
          headers,
        })
        if (!response.ok) throw new Error(response.statusText)
        const data = await response.text()
        return data
      },
      put: async (id: string, image: File, token: string): Promise<string> => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/companies/${id}/logo`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`

        const body = new FormData()
        body.append('file', image)

        const response = await fetch(url, {
          method: 'PUT',
          headers,
          body,
        })

        if (!response.ok) throw new Error(response.statusText)

        const data: string = await response.text()
        return data
      },
      delete: async (id: string, token: string): Promise<void> => {
        const url = `${
          import.meta.env.VITE_RESALE_API_HOST
        }/companies/${id}/logo`
        const headers: HeadersInit = {}
        headers.Authorization = `Bearer ${token}`
        headers.Accept = 'application/json'
        headers['Content-Type'] = 'application/json'

        const response = await fetch(url, {
          method: 'DELETE',
          headers,
        })
        if (!response.ok) throw new Error(response.statusText)
      },
    },
  },
}
