import axios from 'axios'
import {
  FeatureFlagCreateDto,
  FeatureFlagUpdateDto,
  FeatureFlag,
  FeatureFlagResponseDto,
} from '../schemas'

export default {
  post: async (body: FeatureFlagCreateDto, token: string) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/feature-flags`
    const { data } = await axios.post<FeatureFlag>(url, body, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    return data
  },
  get: async (options?: { noCache?: boolean }) => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/feature-flags`
    const headers: { [key: string]: string } = {}
    if (options?.noCache) {
      headers['Cache-Control'] = 'no-cache'
      headers['Pragma'] = 'no-cache'
      headers['Expires'] = '0'
    }
    const { data } = await axios.get<FeatureFlagResponseDto>(url, {
      headers,
    })
    return data
  },
  name: {
    get: async (name: string) => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/feature-flags/${name}`
      const { data } = await axios.get<FeatureFlag>(url)
      return data
    },
  },
  id: {
    patch: async (id: string, body: FeatureFlagUpdateDto, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/feature-flags/${id}`
      const { data } = await axios.patch<FeatureFlag>(url, body, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
    delete: async (id: string, token: string) => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/feature-flags/${id}`
      const { data } = await axios.delete<FeatureFlag>(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      return data
    },
  },
}
