import { RoleRequestCreateDto, RoleRequestResponseDto } from '../schemas'

export default {
  post: async (
    body: RoleRequestCreateDto,
    token: string,
  ): Promise<RoleRequestResponseDto> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/role-requests`
    console.log('create role request on: ', url)

    const response = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: RoleRequestResponseDto = await response.json()
    return data
  },
  get: async (token: string): Promise<RoleRequestResponseDto[]> => {
    const url = `${import.meta.env.VITE_RESALE_API_HOST}/role-requests`

    console.log('get role requests from: ', url)
    const response = await fetch(url, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    if (!response.ok) throw new Error(response.statusText)

    const data: RoleRequestResponseDto[] = await response.json()
    return data
  },
  id: {
    get: async (id: string, token: string): Promise<RoleRequestResponseDto> => {
      const url = `${import.meta.env.VITE_RESALE_API_HOST}/role-requests/${id}`

      console.log('get role request from: ', url)
      const response = await fetch(url, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)

      const data: RoleRequestResponseDto = await response.json()
      return data
    },
    approve: async (id: string, token: string): Promise<void> => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/role-requests/${id}/approve`

      console.log('approve role request on: ', url)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)
    },
    decline: async (id: string, token: string): Promise<void> => {
      const url = `${
        import.meta.env.VITE_RESALE_API_HOST
      }/role-requests/${id}/decline`

      console.log('decline role request on: ', url)
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      if (!response.ok) throw new Error(response.statusText)
    },
  },
}
