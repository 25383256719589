import { getAuth } from 'firebase/auth'
import { ref } from 'vue'
import { createI18n } from 'vue-i18n'

export enum Language {
  DE = 'de',
  FR = 'fr',
  EN = 'en',
  IT = 'it',
}

const currentLanguage = ref<Language>()

const availableLanguages = ref<Language[]>([
  Language.DE,
  Language.EN,
  Language.FR,
  Language.IT,
])

const doc = document.firstElementChild

const getApplicationLanguage = () => {
  const applicationLanguage = localStorage.getItem('lang')
  if (applicationLanguage) {
    return applicationLanguage as Language
  } else {
    const userLanguage = navigator.language
    for (const language of availableLanguages.value) {
      const hasLanguageMatched = userLanguage.startsWith(language)
      if (hasLanguageMatched) {
        return language as Language
      }
    }
  }
  return Language.EN
}

const setLanguage = async (newLanguage: Language) => {
  const auth = getAuth()
  await loadLocalMessages(newLanguage)
  currentLanguage.value = newLanguage
  localStorage.setItem('lang', currentLanguage.value)
  doc?.setAttribute('lang', currentLanguage.value)
  auth.languageCode = currentLanguage.value
  setCookieSettingsLanguage()
}

const setCookieSettingsLanguage = () => {
  // treats windows as any to call OneTrust library
  window?.OneTrust?.changeLanguage()
}

const loadLocalMessages = async (lang: Language) => {
  const messages = await import(
    `../assets/i18n/locales/${lang.toString()}.json`
  )
  i18nInstance.global.setLocaleMessage(lang.toString(), messages.default)
}

const i18nInstance = createI18n({
  legacy: false,
  locale: getApplicationLanguage(),
  globalInjection: true,
})

const getNameByLanguage = (
  language: Language,
  item: Record<string, string | undefined>,
): string | undefined => {
  const key = `name${language.charAt(0).toUpperCase()}${language.slice(1)}`
  return item[key]
}

export function useLanguage() {
  return {
    availableLanguages,
    i18nInstance,
    currentLanguage,
    loadLocalMessages,
    getApplicationLanguage,
    setLanguage,
    getNameByLanguage,
  }
}

declare global {
  interface Window {
    OneTrust?: {
      changeLanguage: () => void
      Init: () => void
      LoadBanner: () => void
      ToggleInfoDisplay: () => void
    }
  }
}
